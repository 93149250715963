import { Button, Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import { fetchShoppingProducts, fetchStates } from 'src/actions/appActions';
import { updateMagentoData } from 'src/actions/authActions';
import ChallengeLegalCheck from 'src/components/challenge/ChallengeLegalCheck';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import { IRootReducers } from 'src/reducers';
import {
  CampaignModuleStatusEnum,
  ResourceTypeEnum,
  UserStatusEnum,
} from 'src/shared/enums';
import {
  IChallengeResourceConfig,
  IChallengeResourceRender,
  IState,
} from 'src/shared/models';
import { buildOrderData, handleShoppingCartDirection } from 'utils';
import { IResourceList } from '../challenge/ChallengeInterfaces';
import CustomModal from '../CustomModal';
import AddressModal from './AddressModal';
import {
  buildCartInfo,
  getOrderProducts,
  getShoppingCartLabel,
  getShoppingCartStatusLabel,
  IOrderProduct,
  modifyProductQuantity,
} from './ShoppingCart.utils';
import ShoppingGroup from './ShoppingCartGroup';
import { YuIcon } from '@isdin/yuma-react-web-pin';

export const ConditionsModal = ({
  formatMessage,
  conditionsContent,
  setShowConditionsModal,
  showConditionsModal,
}) => {
  return (
    <CustomModal
      closeIcon={<CustomCloseIcon />}
      centered
      content={
        <Col
          className="custom-modal-container"
          span={24}
          style={{ overflowY: 'auto', maxHeight: '500px', height: '90%' }}
        >
          <Row
            justify="start"
            align="middle"
            className="conditions-modal__title"
          >
            {formatMessage({ id: 'shopping-cart.conditions-modal.title' })}
          </Row>
          <div
            className="conditions-modal__content block--small regular-body"
            dangerouslySetInnerHTML={{
              __html: conditionsContent,
            }}
          />
        </Col>
      }
      customClass="shopping-cart-modal conditions-modal"
      hideFooter={true}
      onCancel={() => setShowConditionsModal(false)}
      showIcon={false}
      visible={showConditionsModal}
    />
  );
};

interface IOrderRequest {
  idResource: number;
  products: IOrderProduct[];
  addressData?;
  legalCheck: ILegalCheck;
}

interface ILegalCheck {
  isAccepted: boolean;
  idResource: number;
}

interface IProps extends IChallengeResourceRender {
  config: IChallengeResourceConfig;
  resource: IResourceList;
  stateList: IState;
}

type IShoppingCart = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;

export const CustomCloseIcon = () => {
  return (
    <Row className="challengeDetail__modal__close-icon">
      <YuIcon name="Cross" size="M" />
    </Row>
  );
};

const ShoppingCart: FC<IShoppingCart> = ({
  config,
  customer,
  fetchShoppingProducts,
  fetchStates,
  resource,
  shoppingProducts,
  stateList,
  updateChallenge,
  updateMagentoData,
  updateUserPoints,
  userCountry,
  user,
  salesforceCenter,
  userStatus,
}) => {
  const { isCompleted: cartCompleted, isCampaignActivator, icon } = config;
  const [shoppingInfo, setShoppingInfo] = useState(
    buildCartInfo(resource, shoppingProducts)
  );
  const { salesforce } = user;
  const userCanPerformRequest = [
    UserStatusEnum.ACCEPTED_EMPLOYEE,
    UserStatusEnum.ACCEPTED_EMPLOYEE_PIN,
    UserStatusEnum.ACCEPTED_MANAGER,
  ].includes(userStatus);

  const [canCheckout, setCanCheckout] = useState(
    shoppingInfo.isCorrect && userCanPerformRequest
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [legalCheckData, setLegalCheckData] = useState<ILegalCheck>({
    idResource: 0,
    isAccepted: false,
  });

  const { formatMessage, formatNumber } = useIntl();

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      await fetchShoppingProducts();
      setLoading(false);
    };

    (async () => await getProducts())();
  }, []);

  useEffect(() => {
    if (showCheckoutModal || showConditionsModal)
      document.body.classList.add('not-overflow');
    else {
      document.body.classList.remove('not-overflow');
    }
  }, [showCheckoutModal, showConditionsModal, selectedAddress]);

  useEffect(() => {
    setShoppingInfo(buildCartInfo(resource, shoppingProducts));
  }, [shoppingProducts]);

  useEffect(() => {
    if (!userCanPerformRequest) return;

    const resourceLegalCheck = resource.resourceList?.find(
      ({ idResourceType: { idResourceType }, status }) =>
        idResourceType === ResourceTypeEnum.LEGAL_CHECK && status
    );

    setCanCheckout(
      shoppingInfo.isCorrect &&
        shoppingInfo.isStockCorrect &&
        shoppingInfo.groupsQuantity !== 0 &&
        (resourceLegalCheck ? legalCheckData.isAccepted : true)
    );

    // if (!shoppingInfo.isStockCorrect) config.isBlocked = true;
  }, [shoppingInfo, legalCheckData]);

  const handleChangeQuantity = (idResource: number, quantity: number) => {
    setShoppingInfo(modifyProductQuantity(shoppingInfo, idResource, quantity));
  };

  const handleOrderProducts = async (createdAddress) => {
    setShowCheckoutModal(false);
    setShowOrderModal(true);
    setLoading(true);

    const data: IOrderRequest = {
      idResource: resource.idResource,
      products: getOrderProducts(shoppingInfo),
      legalCheck: legalCheckData,
    };

    try {
      if (handleShoppingCartDirection() && (selectedAddress || createdAddress))
        data.addressData = buildOrderData({
          targetAddress: selectedAddress || createdAddress,
          user,
          salesforceCenter,
          salesforce,
          streeInOneLine: true,
        });

      const response = await api.postDataCall({
        dataPath: apiPaths.CHALLENGES.COMPLETE_SHOPPING_CART,
        data,
        callConfig: {},
      });

      const { score, challenge, address } = response.data;

      if (score && typeof score === 'number') updateUserPoints(score);
      if (address && typeof address === 'string') setUserAddress(address);

      updateChallenge(challenge);
    } catch (err) {
      const stringError: string = err?.response?.data?.message || (
        <FormattedMessage id="register.submit.error" />
      );
      setError(stringError);
    } finally {
      setLoading(false);
    }
  };

  const handleClickOrder = () => {
    if (!canCheckout) return setShowConditionsModal(true);
    if (handleShoppingCartDirection()) return setShowCheckoutModal(true);
    return handleOrderProducts(null);
  };

  const handleCloseOrder = () => {
    setShowOrderModal(false);
  };

  const button = !cartCompleted && (
    <Row justify="center">
      <Button
        className={`btn-primary btn-primary--black shopping-cart__send-button ${
          !canCheckout ? 'btn-disabled' : ''
        }`}
        onClick={handleClickOrder}
      >
        {shoppingInfo.buttonText}
      </Button>
    </Row>
  );

  useEffect(() => {
    if (!stateList) (async () => fetchStates())();
  }, []);

  const header = (
    <Row
      justify="center"
      className="shopping-cart-header"
      onClick={() => setShowConditionsModal(true)}
    >
      {formatMessage({ id: 'shopping-cart.conditions-label' })}
    </Row>
  );

  const addressModal = (
    <AddressModal
      customer={customer}
      setShowCheckoutModal={setShowCheckoutModal}
      showCheckoutModal={showCheckoutModal}
      updateMagentoData={updateMagentoData}
      userCountry={userCountry}
      handleOrderProducts={handleOrderProducts}
      selectedAddress={selectedAddress}
      setSelectedAddress={setSelectedAddress}
    />
  );

  const orderModalContent = userAddress ? (
    <>
      <Row justify="center" className="block--small title order-modal">
        {formatMessage({ id: 'shopping-cart.label.order-success' })}
      </Row>
      <Row justify="center" className="block--small regular-body">
        {userAddress}
      </Row>
    </>
  ) : (
    <Row justify="center" className="block--small title order-modal">
      {formatMessage({ id: 'shopping-cart.label.order-success-no-address' })}
    </Row>
  );

  const orderModal = (
    <CustomModal
      {...{
        content: orderModalContent,
        customClass: 'quiz-modal-finalize',
        error,
        footer: null,
        loading,
        onCancel: handleCloseOrder,
        centered: true,
        showIcon: true,
        visible: showOrderModal,
      }}
    />
  );

  const shoppingCartStatusLabel = (statusLabel) => {
    return (
      <Row
        justify="center"
        align="middle"
        className={`shopping-cart__status-label shopping-cart__status-label--${statusLabel.style}`}
      >
        {statusLabel.icon && (
          <i className={`icon icon--${statusLabel.icon}`}></i>
        )}
        <p>
          <FormattedMessage
            id={statusLabel.label}
            values={{
              a: (chunks) => (
                <a target="_self" href={statusLabel.labelLink}>
                  {chunks}
                </a>
              ),
            }}
          />
        </p>
      </Row>
    );
  };

  const component = (
    <>
      <ChallengeResourceWrapper_Blocked />
      {header}
      {shoppingInfo.groups?.map((group, index) => (
        <ShoppingGroup
          {...{
            config,
            group,
            handleChangeQuantity,
            key: index,
            showPrices: shoppingInfo.showPrices,
          }}
        />
      ))}

      <ChallengeLegalCheck
        {...{
          mainResource: resource,
          updateLegalData: setLegalCheckData,
        }}
      />

      {button}

      {addressModal}
      <ConditionsModal
        {...{
          formatMessage,
          showConditionsModal,
          setShowConditionsModal,
          conditionsContent: shoppingInfo.conditionsContent,
        }}
      />
      {orderModal}
      {[
        CampaignModuleStatusEnum.PROCESSED,
        CampaignModuleStatusEnum.SHOPPING_CART_ERROR,
        CampaignModuleStatusEnum.SHOPPING_CART_IN_PROGRESS,
      ].includes(config.status) &&
        shoppingCartStatusLabel(getShoppingCartStatusLabel(config.status))}
    </>
  );

  return (
    <ChallengeResourceWrapper
      bottomLabel={getShoppingCartLabel({
        isCompleted: cartCompleted,
        isActivator: isCampaignActivator,
        isStockCorrect: shoppingInfo.isStockCorrect,
      })}
      children={component}
      description={shoppingInfo.description}
      icon={icon}
      iconWithBackground={true}
      points={formatMessage(
        { id: 'page.challenge.plus-sign-{points}-points' },
        { points: formatNumber(shoppingInfo.points) }
      )}
      styleClass="challengeDetail--ShoppingCart"
      title={shoppingInfo.name}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    customer: state.auth.user.magento,
    shoppingProducts: state.app.shoppingProducts,
    stateList: state.app.stateList,
    userCountry: state.auth.user?.salesforce?.userCountry,
    salesforceAddresses: state.auth.user.salesforce?.addresses,
    salesforceCenter: state.app.salesforceCenter,
    user: state.auth.user,
    userStatus: state.auth.userStatus,
    // TODO: RESTORE
    // salesforceAddresses: state.auth.user.salesforce.addresses.filter(
    //   ({ addressDelivery }) => addressDelivery,
    // ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { fetchShoppingProducts, fetchStates, updateMagentoData },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
