import {
  IResourceDetailList,
  IResourceList,
} from 'src/components/challenge/ChallengeInterfaces';
import {
  ResourceTypeDetailEnum,
  UserResourceUploadMediaStatusEnum,
} from 'src/shared/enums';
import {
  IUserUploadMedia,
  IUserUploadMediaData,
  MediaTypeEnum,
} from 'src/shared/models';
import { checkUserStatus } from 'utils';

const PNG = 'image/png';
const JPG = 'image/jpg';
const JPEG = 'image/jpeg';
const OEG = 'audio/ogg';
const MPEG = 'video/mpeg';
const MP4 = 'video/mp4';
const WEBM = 'video/webm';
const XMVI = 'video/x-msvideo';

abstract class IChallengeUserUploadMediaFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(visibility: IUserUploadMedia): IUserUploadMedia;
}

class DescriptionFiller extends IChallengeUserUploadMediaFiller {
  fill(visibility: IUserUploadMedia): IUserUploadMedia {
    return {
      ...visibility,
      description: this.detail.value,
    };
  }
}

class ButtonTitleFiller extends IChallengeUserUploadMediaFiller {
  fill(visibility: IUserUploadMedia): IUserUploadMedia {
    return {
      ...visibility,
      buttonTitle: this.detail.value,
    };
  }
}

class AllowedExtensionsFiller extends IChallengeUserUploadMediaFiller {
  fill(visibility: IUserUploadMedia): IUserUploadMedia {
    let allowedExtensions: string[] = [];
    let type: MediaTypeEnum;

    switch (parseInt(this.detail.value)) {
      case MediaTypeEnum.IMAGE:
        allowedExtensions = [PNG, JPG, JPEG];
        type = MediaTypeEnum.IMAGE;
        break;
      case MediaTypeEnum.VIDEO:
        allowedExtensions = [OEG, MPEG, MP4, WEBM, XMVI];
        type = MediaTypeEnum.VIDEO;
        break;
      case MediaTypeEnum.URL:
        type = MediaTypeEnum.URL;
        break;
    }

    return {
      ...visibility,
      allowedExtensions,
      mediaData: {
        ...visibility?.mediaData,
        type,
      },
    };
  }
}

class LinkStaticPageFiller extends IChallengeUserUploadMediaFiller {
  fill(visibility: IUserUploadMedia): IUserUploadMedia {
    return {
      ...visibility,
      linkStaticPage: this.detail.value,
    };
  }
}

class EndDateFiller extends IChallengeUserUploadMediaFiller {
  fill(visibility: IUserUploadMedia): IUserUploadMedia {
    return {
      ...visibility,
      endDate: new Date(this.detail.value),
    };
  }
}

class ChallengeVisibilityFactory {
  private readonly userUploadMedia: Map<
    ResourceTypeDetailEnum,
    IChallengeUserUploadMediaFiller
  >;

  constructor(detail: IResourceDetailList) {
    this.userUploadMedia = new Map();
    this.userUploadMedia.set(
      ResourceTypeDetailEnum.CHALLENGE_VISIBILITY_DESCRIPTION,
      new DescriptionFiller(detail)
    );
    this.userUploadMedia.set(
      ResourceTypeDetailEnum.CHALLENGE_VISIBILITY_BUTTON_TITLE,
      new ButtonTitleFiller(detail)
    );
    this.userUploadMedia.set(
      ResourceTypeDetailEnum.CHALLENGE_VISIBILITY_ALLOWED_EXTENSIONS,
      new AllowedExtensionsFiller(detail)
    );
    this.userUploadMedia.set(
      ResourceTypeDetailEnum.CHALLENGE_VISIBILITY_END_DATE,
      new EndDateFiller(detail)
    );
    this.userUploadMedia.set(
      ResourceTypeDetailEnum.CHALLENGE_VISIBILITY_LINK_STATIC_PAGES,
      new LinkStaticPageFiller(detail)
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    visibility: IUserUploadMedia
  ): IUserUploadMedia {
    const visibilityFiller = this.userUploadMedia.get(idResourceTypeD);

    if (visibilityFiller) {
      return visibilityFiller.fill(visibility);
    }

    return visibility;
  }
}

class ChallengeVisibilityBuilder {
  public build(
    resourceList: IResourceList,
    idChallenge: number
  ): IUserUploadMedia {
    const fileData = resourceList.usersM2MList.find(({ status }) => status);
    let visibility: IUserUploadMedia;

    resourceList.resourceDetailList
      .filter((resourceGuide) => resourceGuide.status)
      .forEach((detail) => {
        const Factory = new ChallengeVisibilityFactory(detail);

        visibility = Factory.fill(detail, visibility);
      });

    const mediaData: IUserUploadMediaData = {
      fileName: fileData?.textMachine?.split('|')[0] ?? '',
      idUserResource: fileData?.idUserResource,
      isFirstTime: resourceList.usersM2MList.length === 0,
      isUploaded: checkUserStatus(resourceList, [
        UserResourceUploadMediaStatusEnum.PENDING,
        UserResourceUploadMediaStatusEnum.DENY,
        UserResourceUploadMediaStatusEnum.VALIDATE,
      ]),
      size: fileData?.textMachine?.split('|')[1] ?? '',
      url: fileData?.value,
      type: visibility?.mediaData?.type,
      statusResource:
        resourceList.usersM2MList.pop()?.statusResource ||
        UserResourceUploadMediaStatusEnum.NOT_SENT,
    };

    return {
      ...visibility,
      idChallenge,
      idResource: resourceList.idResource,
      mediaData,
      icon: 'challenge',
    };
  }
}

export const buildChallengeUserUploadMedia = (
  resourceList: IResourceList,
  idChallenge: number
): IUserUploadMedia => {
  const builder = new ChallengeVisibilityBuilder();

  return builder.build(resourceList, idChallenge);
};
