// TODO: Set correct naming
// PIN_PT

// PIN_US || ZA_US
export type PlatformIDEnum =
  | PIN_PlatformID_Enum
  | LEGACY_PlatformID_Enum
  | CON_PlatformID_Enum;

export enum LEGACY_PlatformID_Enum {
  LIWW_USA = 'liww_usa', // TODO: pendiente de que cambie a pin-us
  PIN_USA = 'pin-us',
}

export enum PIN_PlatformID_Enum {
  PIN_ARGENTINA = 'pin-ar',
  PIN_BRAZIL = 'pin-br',
  PIN_CHILE = 'pin-cl',
  PIN_COLOMBIA = 'pin-co',
  PIN_ALEMANIA = 'pin-de',
  PIN_ITALY = 'pin-it',
  PIN_MEXICO = 'pin-mx',
  PIN_PERU = 'pin-pe',
  PIN_PORTUGAL = 'pin-pt',
  PIN_SPAIN = 'pin-es',
  PIN_GUATEMALA = 'pin-gt',
}

export enum PIN_INFILTRATED_COUNTRIES_Enum {
  ALEMANIA = 'DE',
  AUSTRIA = 'AT',
  ESPAÑA = 'ES',
  ANDORRA = 'AD',
  ITALIA = 'IT',
  VATICANO = 'VA',
  SAN_MARINO = 'SD',
  COLOMBIA = 'CO',
  GUATEMALA = 'GT',
}

export enum PIN_INFILTRATED_COUNTRIES_NAMES_Enum {
  ALEMANIA = 'Deutschland',
  AUSTRIA = 'Österreich',
  ESPAÑA = 'España',
  ANDORRA = 'Andorra',
  ITALIA = 'Italia',
  VATICANO = 'Vaticano',
  SAN_MARINO = 'San Marino',
  COLOMBIA = 'Colombia',
}

export enum CON_PlatformID_Enum {
  CON_SPAIN = 'con-es',
}

export enum SalesforceMedicalCenter {
  AMBULATORY = '02',
  PRIVATE_CENTER = '07',
  PRIVATE_CONSULTATION = '08',
  HOSPITAL = '09',
  MEDICAL_SPA = '10',
  MUTUAL = '05',
  MVZ = 'MVZ',
  OTHER = 'Other',
  PUBLIC_PRACTICE = 'Public practice',
}

export enum ChannelType {
  NATIONAL = '10',
  INTERNATIONAL = '20',
  CANARIAS = '30',
}

export const isNPIRequiredInPlatform = (platform) =>
  [
    PIN_PlatformID_Enum.PIN_BRAZIL,
    PIN_PlatformID_Enum.PIN_MEXICO,
    PIN_PlatformID_Enum.PIN_GUATEMALA,
  ].includes(platform);

export const isInfiltratedCountryRequired = (platform) =>
  [
    PIN_PlatformID_Enum.PIN_ALEMANIA,
    PIN_PlatformID_Enum.PIN_ITALY,
    PIN_PlatformID_Enum.PIN_SPAIN,
  ].includes(platform);

export const shouldHidePostalCode = (platform) =>
  [
    PIN_PlatformID_Enum.PIN_COLOMBIA,
    PIN_PlatformID_Enum.PIN_CHILE,
    PIN_PlatformID_Enum.PIN_PERU,
    PIN_PlatformID_Enum.PIN_GUATEMALA,
    PIN_PlatformID_Enum.PIN_COLOMBIA,
  ].includes(platform);

export const shouldHideWorkCenter = (platform) =>
  [
    PIN_PlatformID_Enum.PIN_PERU,
    PIN_PlatformID_Enum.PIN_COLOMBIA,
    PIN_PlatformID_Enum.PIN_CHILE,
    PIN_PlatformID_Enum.PIN_COLOMBIA,
    PIN_PlatformID_Enum.PIN_GUATEMALA,
    PIN_PlatformID_Enum.PIN_MEXICO,
    PIN_PlatformID_Enum.PIN_BRAZIL,
    PIN_PlatformID_Enum.PIN_PORTUGAL,
    PIN_PlatformID_Enum.PIN_ITALY,
    PIN_PlatformID_Enum.PIN_ALEMANIA,
    LEGACY_PlatformID_Enum.PIN_USA,
  ].includes(platform);

export const shouldHideCollegiateNumber = (platform) =>
  [PIN_PlatformID_Enum.PIN_ARGENTINA].includes(platform);

export const documentIdOnlyNumbers = (platform) =>
  [
    PIN_PlatformID_Enum.PIN_ARGENTINA,
    PIN_PlatformID_Enum.PIN_COLOMBIA,
    PIN_PlatformID_Enum.PIN_PORTUGAL,
  ].includes(platform);

export const collegiateNumberOnlyNumbers = (platform) =>
  [
    PIN_PlatformID_Enum.PIN_COLOMBIA,
    PIN_PlatformID_Enum.PIN_MEXICO,
    PIN_PlatformID_Enum.PIN_ALEMANIA,
  ].includes(platform);

export const customZipCodeFormatting = (platform) =>
  [PIN_PlatformID_Enum.PIN_PORTUGAL].includes(platform);

export const shouldGoToDelegatePage = () => false;
