import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';
import { useYuInputDateCountryDate } from './useYuInputDateCountryDate';

export const useYuInputDateHelperFunctions = () => {
  const intl = useIntl();
  const countryDate = useYuInputDateCountryDate();

  const dateToYuInputDateString = (date: Date | string): string => {
    if (!date) return date?.toString();
    const ldate = DateTime.fromJSDate(new Date(date));
    ldate.setLocale(intl.locale);

    const str =
      countryDate === 'US'
        ? ldate.toFormat('MM/dd/yyyy')
        : ldate.toLocaleString(DateTime.DATE_SHORT);
    const split = str.split('/');
    return `${split[0].padStart(2, '0')}/${split[1].padStart(2, '0')}/${
      split[2]
    }`;
  };

  const yuInputDateStringToDate = (str: string): Date => {
    const split = str.split('/');
    if (countryDate === 'EU') {
      return new Date(Number(split[2]), Number(split[1]) - 1, Number(split[0]));
    } else {
      return new Date(Number(split[2]), Number(split[0]) - 1, Number(split[1]));
    }
  };

  return {
    dateToYuInputDateString,
    yuInputDateStringToDate,
  };
};
