import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, InputNumber, Row } from 'antd';
import { config } from 'config/config';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { IRequestOrderProduct } from '../shared/models';
import { getCurrencyFormat } from 'utils';

interface OwnProps {
  index: number;
  product: IRequestOrderProduct;
  resetQuantity: boolean;
  updateQuantity: (quantity: number) => void;
}

const formatNumber = (value: string) => {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }

  const _result = `${prefix}${result}${list[1] ? `.${list[1]}` : ''}` || '0';

  return `${parseFloat(_result)}`;
};

export const RequestOrderProductMobile: (props: OwnProps) => JSX.Element = ({
  index,
  product,
  resetQuantity,
  updateQuantity,
}) => {
  const [quantity, setQuantity] = useState<number>(0);
  const [error, setError] = useState(!product.image);

  const increaseQuantity = () => {
    const _quantity = quantity + 1;
    setQuantity(_quantity);
    updateQuantity(quantity + 1);
  };

  const reduceQuantity = () => {
    const _quantity = Math.max(0, quantity - 1);
    setQuantity(_quantity);
    updateQuantity(_quantity);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const _quantity = parseInt(event.target.value);
    setQuantity(_quantity);
    updateQuantity(_quantity);
  };

  useEffect(() => {
    if (resetQuantity) setQuantity(0);
  }, [resetQuantity]);

  return (
    <Row
      className="table-row-product table-row-product--mobile"
      align="middle"
      justify="space-between"
      key={index}
    >
      <Col className="column-image" span={8}>
        <img
          id={`product-${index}`}
          loading="lazy"
          src={error ? `${config.APP.ASSET_PREFIX}/noimage.png` : product.image}
          onError={() => setError(true)}
        />
      </Col>
      <Col className="column-row-mobile" span={16}>
        <Row className="request-order-mobile-row column-title" align="middle">
          <Col>
            <span>{product.name}</span>
            <span>{product.nationalCode}</span>
          </Col>
        </Row>
        <Row className="request-order-mobile-row column-pvl" align="middle">
          <Col span={10}>
            <FormattedMessage id="page.request-order.wholesaler-price" />
          </Col>
          <Col span={14}>
            <FormattedNumber
              value={product.pvl}
              style="currency"
              currency={getCurrencyFormat()}
            />
          </Col>
        </Row>
        <Row className="request-order-mobile-row" align="middle">
          <Col span={10}>
            <FormattedMessage id="page.request-order.MSRP" />
          </Col>
          <Col span={14} className="column-pvpr">
            <FormattedNumber
              value={product.pvpr}
              style="currency"
              currency={getCurrencyFormat()}
            />
          </Col>
        </Row>
        <Row className="request-order-mobile-row column-units" align="middle">
          <Col span={10}>
            <FormattedMessage id="page.request-order.units" />
          </Col>
          <Col span={14}>
            <Row justify="space-between" align="middle">
              <Col onClick={reduceQuantity} span={5}>
                <MinusCircleOutlined />
              </Col>

              <InputNumber
                className="input-number"
                value={quantity}
                formatter={formatNumber}
                onBlur={handleOnBlur}
                min={0}
              />
              <Col onClick={increaseQuantity} span={5}>
                <PlusCircleOutlined />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const RequestOrderProduct: (props: OwnProps) => JSX.Element = ({
  index,
  product,
  resetQuantity,
  updateQuantity,
}): JSX.Element => {
  const [quantity, setQuantity] = useState<number>(0);
  const [error, setError] = useState(!product.image);

  const increaseQuantity = () => {
    const _quantity = quantity + 1;
    setQuantity(_quantity);
    updateQuantity(_quantity);
  };

  const reduceQuantity = () => {
    const _quantity = Math.max(0, quantity - 1);
    setQuantity(_quantity);
    updateQuantity(_quantity);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const _quantity = parseInt(event.target.value);
    setQuantity(_quantity);
    updateQuantity(_quantity);
  };

  useEffect(() => {
    if (resetQuantity) setQuantity(0);
  }, [resetQuantity]);

  return (
    <Row
      className="table-row-product"
      align="middle"
      justify="space-between"
      key={index}
    >
      <Col className="column-image" span={2}>
        <img
          id={`product-${index}`}
          loading="lazy"
          src={error ? `${config.APP.ASSET_PREFIX}/noimage.png` : product.image}
          onError={() => setError(true)}
        />
      </Col>
      <Col className="column-title" span={10}>
        <Row> {product.name}</Row>
        <Row>{product.nationalCode}</Row>
      </Col>
      <Col className="column-pvl" span={3}>
        <FormattedNumber
          value={product.pvl}
          style="currency"
          currency={getCurrencyFormat()}
        />
      </Col>
      <Col className="column-pvpr" span={3}>
        <FormattedNumber
          value={product.pvpr}
          style="currency"
          currency={getCurrencyFormat()}
        />
      </Col>
      <Col className="column-units" span={6}>
        <Row justify="center" align="middle">
          <Col onClick={reduceQuantity}>
            <MinusCircleOutlined />
          </Col>
          <InputNumber
            className="input-number"
            value={quantity}
            onBlur={handleOnBlur}
            formatter={formatNumber}
            min={0}
          />
          <Col onClick={increaseQuantity}>
            <PlusCircleOutlined />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
