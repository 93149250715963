import { ChannelType } from 'src/shared/enums/platform.enum';
import { IRegion } from 'src/shared/models';

export const PIN_CL: IRegion[] = [
  {
    id: 'a0C5800000FiEMYEA3',
    code: '02',
    name: 'Antofagasta',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiENfEAN',
    code: '15',
    name: 'Arica y Parinacota',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERcEAN',
    code: '08',
    name: 'Bío-Bío',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERhEAN',
    code: '03',
    name: 'Atacama',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiERxEAN',
    code: '11',
    name: 'Aysén',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEWcEAN',
    code: '01',
    name: 'Tarapacá',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEYgEAN',
    code: '04',
    name: 'Coquimbo',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEezEAF',
    code: '10',
    name: 'Los Lagos',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEf4EAF',
    code: '12',
    name: 'Magallanes',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEfOEAV',
    code: '06',
    name: "Libertador O'Higgins",
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEh4EAF',
    code: '13',
    name: 'Santiago',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEj1EAF',
    code: '07',
    name: 'Maule',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEjLEAV',
    code: '09',
    name: 'La Araucanía',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkWEAV',
    code: '14',
    name: 'Los Ríos',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C5800000FiEkaEAF',
    code: '05',
    name: 'Valparaiso',
    channel: ChannelType.NATIONAL,
  },
  {
    id: 'a0C4I00000g345xUAA',
    code: '16',
    name: 'Ñuble',
    channel: ChannelType.NATIONAL,
  },
].sort(({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, 'es-ES');
});
