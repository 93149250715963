import { notification } from 'antd';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from 'config/config';
import { logout } from 'src/actions/authActions';
import { ChallengeTypeEnum, UserReferralData } from 'src/shared/enums';
import { store } from 'src/store';
import { authTypes } from 'src/types/authTypes';
import { getCookie, removeCookieFromClient, setCookieFromClient } from 'utils';
import {
  getSanitizeTransaction,
  ITransactionDTO,
  ITransactionsResponse,
  IUser,
} from '../src/shared/models';
import apiPaths from './apiPaths';
import type { IncomingMessage } from 'http';

const { API, COOKIES } = config;
const { BASE_URL } = API;
const { ACCESS_TOKEN, REFRESH_TOKEN, UUID_USER } = COOKIES;

const baseAxios = axios.create({ baseURL: BASE_URL });
const secureAxios = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

const axiosOnResponseDone = (response) => {
  return response;
};

const axiosOnResponseError = (error) => {
  if (error.response?.data?.message)
    error.message = error.response.data.message;
  if (error.response?.data?.error) error.message = error.response.data.error;
  return Promise.reject(error);
};

secureAxios.interceptors.response.use(
  axiosOnResponseDone,
  axiosOnResponseError
);

baseAxios.defaults.headers.post['Content-Type'] = 'application/json';
secureAxios.defaults.headers.post['Content-Type'] = 'application/json';

const forceLogout = () => {
  logout()();
  removeCookieFromClient(ACCESS_TOKEN);
  removeCookieFromClient(REFRESH_TOKEN);
  removeCookieFromClient(UUID_USER);
};

const refreshCall = async () => {
  const accessToken = getCookie(ACCESS_TOKEN, null);
  const refreshToken = getCookie(REFRESH_TOKEN, null);

  if (!accessToken && !refreshToken) return null;

  const data = { accessToken, refreshToken };
  const url = `${BASE_URL}${apiPaths.AUTH.REFRESH}`;

  return await secureAxios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getRequestHeaders = (accessToken: string) =>
  !accessToken
    ? {}
    : {
        common: {
          Authorization:
            accessToken.indexOf('Bearer') < 0
              ? `Bearer ${accessToken}`
              : accessToken,
          'Content-Type': 'application/json',
        },
      };

secureAxios.interceptors.request.use((req) => {
  const token: string = getCookie(ACCESS_TOKEN, req as IncomingMessage);
  const _token = token ?? store.getState()?.auth?.accessToken;

  return {
    ...req,
    headers: getRequestHeaders(_token),
  };
});

interface iRefreshTokenPool {
  requestCount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promise?: any;
}

const refreshTokenPool: iRefreshTokenPool = {
  requestCount: 0,
};

secureAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      try {
        if (!refreshTokenPool.promise) {
          refreshTokenPool.promise = refreshCall().then((res) => {
            const { accessToken, refreshToken } = res.data;

            setCookieFromClient(ACCESS_TOKEN, accessToken, 1);
            setCookieFromClient(REFRESH_TOKEN, refreshToken, 5);
            return res;
          });
          refreshTokenPool.requestCount = 1;
        } else {
          refreshTokenPool.requestCount++;
        }

        const response = await refreshTokenPool.promise;
        if (response === null || response?.data === null) return;

        const { accessToken, refreshToken } = response.data;
        refreshTokenPool.requestCount--;
        if (refreshTokenPool.requestCount === 0) {
          refreshTokenPool.promise = null;
          store.dispatch({
            type: authTypes.AUTH_SET_TOKENS,
            payload: { accessToken, refreshToken },
          });
        }
        return secureAxios({
          ...error.response.config,
          retry: true,
          headers: getRequestHeaders(accessToken),
        });
      } catch (err) {
        console.error(err);
        notification.info({
          message: 'Session expired',
        });

        forceLogout();
        refreshTokenPool.requestCount = 0;
        refreshTokenPool.promise = null;

        throw err;
      }
    }
    throw error;
  }
);

export const loginCall = async ({
  dataPath = apiPaths.AUTH.LOGIN,
  data,
}: {
  dataPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}) => baseAxios.post(dataPath, data);

export const logoutCall = ({
  dataPath = apiPaths.AUTH.LOGOUT,
}: {
  dataPath: string;
}) => secureAxios.post(dataPath, {});

export const registerCall = ({
  dataPath = apiPaths.AUTH.REGISTER,
  data,
}: {
  dataPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}) =>
  baseAxios.post(dataPath, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

//TODO - Parametrizar estas llamadas
export const getUserInfo = ({
  dataPath = apiPaths.CALL.USER_INFO,
  callConfig = {},
}: {
  dataPath?: string;
  callConfig?: AxiosRequestConfig;
}): Promise<AxiosResponse<IUser>> => secureAxios.get(dataPath, callConfig);

export const getUserReferralData = ({
  dataPath = apiPaths.CALL.USER_REFERRAL_DATA,
  callConfig = {},
}: {
  dataPath?: string;
  callConfig?: AxiosRequestConfig;
}): Promise<AxiosResponse<UserReferralData>> =>
  secureAxios.get(dataPath, callConfig);

export const changePassword = ({
  dataPath = apiPaths.RESET_PASSWORD,
  data,
  callConfig = {},
}: {
  dataPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  callConfig?: AxiosRequestConfig;
}) => baseAxios.post(dataPath, data, callConfig);

export const resetTokenIsValid = ({
  dataPath = apiPaths.RESET_TOKEN_IS_VALID,
  data,
  callConfig = {},
}: {
  dataPath?: string;
  data: {
    token: string;
  };
  callConfig?: Record<string, string>;
}) => baseAxios.post(dataPath, data, callConfig);

export const recoveryPassword = ({
  dataPath = apiPaths.RECOVERY_PASSWORD,
  data,
}: {
  dataPath?: string;
  data?: { email: string };
}) => baseAxios.post(dataPath, data);

export const getSpecialties = ({
  dataPath = apiPaths.CALL.SPECIALTY_LIST,
  callConfig = {},
}: {
  dataPath?: string;
  callConfig?: AxiosRequestConfig;
}) => baseAxios.get(dataPath, callConfig);

export const getChallengesByUser = ({
  dataPath = apiPaths.CHALLENGES.GET_EVENTS,
  callConfig = {},
}: {
  dataPath?: string;
  callConfig?: AxiosRequestConfig;
}) => secureAxios.get(dataPath, callConfig);

export const challengeUserInscription = ({
  dataPath = apiPaths.CHALLENGES.SUBSCRIBE_USER,
  callConfig,
}: {
  dataPath?: string;
  callConfig?: AxiosRequestConfig;
}) => secureAxios.put(dataPath, callConfig);

export const completeSurvey = ({
  dataPath = apiPaths.CALL.COMPLETE_SURVEY,
  data,
  callConfig = {},
}: {
  dataPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  callConfig?: AxiosRequestConfig;
}) => secureAxios.post(dataPath, data, callConfig);

export const completeChallengeSurvey = ({
  dataPath = apiPaths.CALL.COMPLETE_CHALLENGE_SURVEY,
  data,
  callConfig = {},
}: {
  dataPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  callConfig?: AxiosRequestConfig;
}) => secureAxios.post(dataPath, data, callConfig);

export const changeRecoveryPassword = ({
  dataPath = apiPaths.RECOVERY_PASSWORD,
  data,
  callConfig = {},
}: {
  dataPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  callConfig?: AxiosRequestConfig;
}) => baseAxios.post(dataPath, data, callConfig);

export const uploadImage = ({
  dataPath = apiPaths.UPLOAD_IMAGE,
  data,
  callConfig = {},
}: {
  dataPath?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  callConfig?: AxiosRequestConfig;
}) => secureAxios.post(dataPath, data, callConfig);

export const getUserPoints = ({
  dataPath = apiPaths.MAGENTO.GET_CUSTOMER_POINTS,
  callConfig,
}: {
  dataPath?: string;
  callConfig?: AxiosRequestConfig;
}) => secureAxios.get(dataPath, callConfig);

// export const getCategoriesByChallengeType = (
//   challengeType: ChallengeTypeEnum,
//   callConfig: any,
// ) =>
//   getDataCall({
//     dataPath: `${apiPaths.CHALLENGES.GET_CATEGORIES}/${challengeType}`,
//     callConfig,
//   });

export const getCategoriesByChallengeType = (
  challengeType: ChallengeTypeEnum,
  callConfig: AxiosRequestConfig
) =>
  getDataCall({
    dataPath: `${apiPaths.CHALLENGES.GET_FILTERS}/${challengeType}`,
    callConfig,
  });

export const getApiCombo = (idCombo: string) =>
  getDataCall({
    dataPath: `${apiPaths.COMBOS}?id=${idCombo}`,
    callConfig: {},
  });

export const getPublicApiCombo = (idCombo: string) =>
  getDataCall({
    dataPath: `${apiPaths.COMBOS_PUBLIC}?id=${idCombo}`,
    callConfig: {},
  });

export const getRedeemPointsProducts = (query?: string) => {
  return getDataCall({
    dataPath: `${apiPaths.MAGENTO.PRODUCTS}?${query}`,
    callConfig: {},
  });
};
export const getLabelRedeemProducts = () => {
  return getDataCall({
    dataPath: `${apiPaths.LABEL_REDEEM_PRODUCTS}`,
    callConfig: {},
  });
};

export const getOrderedProductBySku = (
  sku: string,
  customDateFormat: string
) => {
  return getDataCall({
    dataPath: `${apiPaths.MAGENTO.GET_ORDER_PRODUCT_BY_SKU}/${sku}?dateLimit=${customDateFormat}`,
    callConfig: {},
  });
};

export const getUserHistoricalPoints = async (
  page: number
): Promise<{ items: Array<ITransactionDTO>; totalCount: number }> => {
  const response: AxiosResponse<ITransactionsResponse> = await getDataCall({
    dataPath: apiPaths.MAGENTO.GET_CUSTOMER_POINTS_HISTORY.replace(
      '{{PAGE_SIZE}}',
      '10'
    ).replace('{{PAGE_NUMBER}}', page.toString()),
    callConfig: {},
  });

  return {
    items: response.data.items.map(getSanitizeTransaction),
    totalCount: response.data.total_count,
  };
};

export const getDataCall = ({ dataPath, callConfig }) =>
  secureAxios.get(dataPath, callConfig);

export const getDataByIdCall = ({ dataPath, id, callConfig }) =>
  secureAxios.get(`${dataPath}/${id}`, callConfig);

export const postDataCall = ({ dataPath, data, callConfig }) =>
  secureAxios.post(dataPath, data, callConfig);

export const postDataCallById = ({
  dataPath,
  id,
  data,
}: {
  dataPath: string;
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}) => secureAxios.post(`${dataPath}/${id}`, data);

export const putDataCall = ({ dataPath, data, callConfig }) =>
  secureAxios.put(dataPath, data, callConfig);

export const putDataCallById = ({
  dataPath,
  id,
  data,
}: {
  dataPath: string;
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}) => secureAxios.put(`${dataPath}/${id}`, data);

export const patchDataCall = ({
  dataPath,
  data,
}: {
  dataPath: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}) => secureAxios.patch(`${dataPath}`, data);

export const deleteDataCall = ({ dataPath, id, callConfig }) =>
  secureAxios.delete(`${dataPath}/${id}`, callConfig);

export const redeemCoupon = (couponCode: string, customerId: number) =>
  postDataCall({
    dataPath: apiPaths.MAGENTO.REDEEM_CUPON,
    data: {
      customerId,
      couponCode,
    },
    callConfig: {},
  });

export const deleteDataCallNoID = ({ dataPath, callConfig }) =>
  secureAxios.delete(`${dataPath}`, callConfig);
