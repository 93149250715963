import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

const hasValue = (value) => value.length > 0 && value.trim() !== '';
const emptyValue = (value) => !hasValue(value);

type Properties = {
  maxLength?: number;
  required?: boolean;
  errorMessage?: ReactNode;
  colectiveValidate?: boolean;
  oneWordInput?: boolean;
  optional?: boolean;
  callbackValidate?: () => unknown;
  helperMessages?: ReactNode[];
  icon?: string;
  options?: {
    label: string;
    value: string;
  }[];
  defaultValue?: string;
};

export const getConfig = (fieldName: string, residenceCountry: string) => {
  return config[fieldName].platform?.[residenceCountry]
    ? config[fieldName].platform[residenceCountry]
    : config[fieldName];
};

const InvalidFormat = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      {formatMessage({
        id: 'form.message.user-document.invalid-format',
      })}
    </>
  );
};

export const config: Record<
  'document_id',
  {
    inputClass: 'checkbox' | 'text' | 'select' | 'radio';
    label?: string;
    validators?: {
      validator: (value: string) => boolean;
      message: ReactNode;
    }[];
    properties?: Properties;
    platform?: Record<
      string,
      {
        validators?: {
          validator: (value: string) => boolean;
          message: ReactNode;
        }[];
        properties?: Properties;
        maxLength?: number;
      }
    >;
  }
> = {
  document_id: {
    inputClass: 'text',
    label: 'form.field.user-document',
    platform: {
      'pin-br': {
        validators: [
          {
            validator: (value) => {
              if (emptyValue(value)) return true;
              const REGEX = /^\d{11}$/;
              return REGEX.test(value.toString());
            },
            message: <InvalidFormat />,
          },
        ],
        properties: {
          maxLength: 11,
          oneWordInput: true,
        },
      },
    },
  },
};
