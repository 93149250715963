import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';

const SkeletonMyOrders: NextPage = (): JSX.Element => {
  const { upXS, upM } = useSize();

  const numberOfCards = 5;

  const orderCard = (index: number) => {
    return (
      <YuSkeletonWrapper
        key={index}
        style={{
          boxShadow: '0 0 1px 0 hsla(0,0%,7%,.3), 0 2px 4px 0 hsla(0,0%,7%,.2)',
        }}
        border="1px solid #f0f0f0"
        marginBottom="XXS"
        height="132px"
        width="100%"
        padding="S"
      >
        <YuFlex flexDirection="row" gap="XS" justify="start">
          <YuSkeleton height={24} width="24px" variant="text" />
          <YuSkeleton height={24} width="212px" variant="text" />
        </YuFlex>
        <YuSpacer size="XS" />
        <YuFlex flexDirection="row" justify="space-between">
          <YuFlex flexDirection="column" gap="XS" justify="start">
            <YuSkeleton height={16} width="124px" variant="text" />
            <YuSkeleton height={16} width="124px" variant="text" />
          </YuFlex>
          <YuFlex flexDirection="column" justify="center" pr="XS">
            <YuSkeleton height={25} width="85px" variant="text" />
          </YuFlex>
        </YuFlex>
      </YuSkeletonWrapper>
    );
  };

  const BottomButtonsMobile = (
    <>
      <YuSpacer size="L" />
      <YuFlex flexDirection="column" gap="XS">
        <YuFlex flexDirection="row" justify="center">
          <YuSkeleton height={18} width="140px" variant="text" />
        </YuFlex>
        <YuFlex flexDirection="row" gap="XS" justify="center">
          <YuSkeleton height={18} width="18px" variant="text" />
          <YuSkeleton height={18} width="150px" variant="text" />
          <YuSkeleton height={18} width="18px" variant="text" />
        </YuFlex>
      </YuFlex>
    </>
  );

  const BottomButtonsTablet = (
    <>
      <YuSpacer size="L" />
      <YuFlex flexDirection="row" justify="space-between">
        <YuSkeleton height={18} width="140px" variant="text" />
        <YuFlex flexDirection="row" gap="XS">
          <YuSkeleton height={18} width="18px" variant="text" />
          <YuSkeleton height={18} width="150px" variant="text" />
          <YuSkeleton height={18} width="18px" variant="text" />
        </YuFlex>
      </YuFlex>
    </>
  );

  const BottomButtonsDesktop = (
    <>
      <YuSpacer size="M" />
      <YuFlex flexDirection="row" justify="space-between">
        <YuSkeleton height={18} width="140px" variant="text" />
        <YuFlex flexDirection="row" gap="XS">
          <YuSkeleton height={18} width="18px" variant="text" />
          <YuSkeleton height={18} width="150px" variant="text" />
          <YuSkeleton height={18} width="18px" variant="text" />
        </YuFlex>
      </YuFlex>
    </>
  );

  const DesktopHeaderDescription = (
    <>
      <YuSpacer size="L" />
      <YuFlex
        flexDirection="row"
        paddingLeft={{ initial: 0, upM: 'M' }}
        maxWidth="1400px"
        style={{ margin: 'auto' }}
      >
        <YuSkeletonWrapper
          paddingLeft="L"
          paddingRight="M"
          paddingTop="L"
          pb="XXL"
          minWidth="400px"
          width="400px"
          backgroundColor="bg.grey"
        >
          <YuFlex gap="M" flexDirection="column">
            <YuSkeletonWrapper
              maxWidth="1400px"
              width="96%"
              style={{
                borderRadius: '4px',
                boxShadow:
                  '0px 0px 1px 0px #1313134D, 0px 4px 8px 0px #13131333',
              }}
              backgroundColor="bg.grey"
              padding="XS"
            >
              <YuFlex flexDirection="row" gap="L">
                <YuFlex gap="XXS" flexDirection="column">
                  <YuSkeleton height={16} width="71px" variant="text" />
                  <YuSkeleton height={24} width="154px" variant="text" />
                </YuFlex>
                <YuSkeleton height={56} width="56px" variant="text" />
              </YuFlex>
            </YuSkeletonWrapper>
            <YuFlex gap="S" flexDirection="column">
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width="24px" variant="text" />
                <YuSkeleton height={16} width="91px" variant="text" />
              </YuFlex>
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width="24px" variant="text" />
                <YuSkeleton height={16} width="116px" variant="text" />
              </YuFlex>
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width="24px" variant="text" />
                <YuSkeleton height={16} width="140px" variant="text" />
              </YuFlex>
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width="24px" variant="text" />
                <YuSkeleton height={16} width="116px" variant="text" />
              </YuFlex>
            </YuFlex>
          </YuFlex>
        </YuSkeletonWrapper>
        <YuSkeletonWrapper
          paddingLeft="XXL"
          paddingTop="L"
          pb="XL"
          width="100%"
          maxWidth={640}
          marginRight="L"
        >
          <YuFlex gap="M" flexDirection="column">
            <YuSkeleton height={32} width="154px" variant="text" />
          </YuFlex>
          <YuSpacer size="S" />
          <YuFlex justify="end">
            <YuSkeleton height={56} width="300px" variant="text" />
          </YuFlex>
          <YuSpacer size="S" />
          {_.times(numberOfCards, (index) => orderCard(index))}
          {BottomButtonsDesktop}
        </YuSkeletonWrapper>
      </YuFlex>
    </>
  );

  const TabletHeaderDescription = (
    <>
      <YuFlex
        paddingTop="S"
        style={{ margin: 'auto' }}
        width="100%"
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
      >
        <YuSkeleton
          height="24px"
          width="150px"
          variant="text"
          alignSelf="start"
        />
      </YuFlex>
      <YuSkeletonWrapper
        paddingTop="M"
        style={{ margin: 'auto' }}
        width="100%"
        maxWidth={640}
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
      >
        <YuFlex justify="center">
          <YuSkeleton height={32} width="154px" variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        <YuFlex justify="end">
          <YuSkeleton height={56} width="300px" variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        {_.times(numberOfCards, (index) => orderCard(index))}
        {BottomButtonsTablet}
        <YuSpacer size="XXXL" />
      </YuSkeletonWrapper>
    </>
  );

  const MobileHeaderDescription = (
    <>
      <YuSkeletonWrapper
        paddingTop="XS"
        style={{ margin: 'auto' }}
        width="100%"
        pl={{ onlyXS: 'S', upXS: 'S' }}
        pr={{ onlyXS: 'S', upXS: 'S' }}
      >
        <YuSkeleton
          height="24px"
          width="150px"
          variant="text"
          alignSelf="start"
        />
        <YuSpacer size="L" />
        <YuFlex justify="center">
          <YuSkeleton height={32} width="154px" variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        <YuFlex justify="end">
          <YuSkeleton height={56} width="100%" variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        {_.times(numberOfCards, (index) => orderCard(index))}
        {BottomButtonsMobile}
        <YuSpacer size="XL" />
      </YuSkeletonWrapper>
    </>
  );

  return upM
    ? DesktopHeaderDescription
    : upXS
    ? TabletHeaderDescription
    : MobileHeaderDescription;
};

export default SkeletonMyOrders;
