import { Button, Row } from 'antd';
import { config } from 'config/config';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import { IRootReducers } from 'src/reducers';
import { IChallengeResourceRender, IChallengeVideo } from 'src/shared/models';
import {
  completeChallengeUserResource,
  getVideoTypeById,
  VideoType,
} from 'utils';

const { YOUTUBE_EMBEDDED_URL, VIMEO_EMBEDDED_URL } = config.APP;

interface OwnProps extends IChallengeResourceRender {
  config: IChallengeVideo;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ChallengeVideo: FC<Props> = ({
  config,
  updateChallenge,
  updateUserPoints,
}) => {
  if (!config) return <React.Fragment />;
  const {
    idResource,
    image: optionalImage,
    isCompleted,
    isVideoFile,
    points,
    title,
    url,
  } = config;
  const domElementID = `challengeVideo-${idResource}`;

  const { formatMessage, formatNumber } = useIntl();
  const [isPlayed, setIsPlayed] = useState(false);
  const [seeImage, setSeeImage] = useState(true);
  const [isVideoActive, setIsVideoActive] = useState(false);

  let videoThumbnail: string;

  let embedVideoUrl = '';

  const videoType = getVideoTypeById(url);

  switch (videoType) {
    case VideoType.YOUTUBE:
      embedVideoUrl = `${YOUTUBE_EMBEDDED_URL}${url}?autoplay=1&enablejsapi=1&mute=1`;
      videoThumbnail = config.videoIsVertical
        ? `https://i.ytimg.com/vi/${url}/frame0.jpg`
        : `https://img.youtube.com/vi/${url}/maxresdefault.jpg`;
      break;
    case VideoType.VIMEO:
      embedVideoUrl = `${VIMEO_EMBEDDED_URL}${url}?autoplay=1&muted=1`;
      videoThumbnail = `https://vumbnail.com/${url}.jpg`;
  }

  const handlePlayVideo = () => {
    const domElement = document.getElementById(
      domElementID
    ) as HTMLVideoElement;

    switch (true) {
      case isVideoFile && !isPlayed:
        setIsPlayed(true);
        setSeeImage(false);
        domElement.play();
        break;
      case !isVideoFile && !isPlayed:
        setIsPlayed(true);
        setIsVideoActive(true);
        document
          .getElementById(domElementID)
          .setAttribute('src', embedVideoUrl);
        break;
      case isVideoFile && isPlayed:
        setIsPlayed(false);
        domElement.pause();
        break;
    }

    handleCompleteModule();
  };

  const handleCompleteModule = () => {
    if (isCompleted) return;
    completeChallengeUserResource({
      idResource,
      updateChallenge,
      updateUserPoints,
    });
  };

  const component = config && (!isVideoFile || url.trim()) && (
    <Row className="challengeVideo" justify="center" align="middle">
      <Row justify="center" className="challengeVideo__videoContainer">
        <ChallengeResourceWrapper_Blocked />

        <div
          className={
            config.videoIsVertical
              ? 'question-video-container-v'
              : 'question-video-container-h'
          }
        >
          <div
            className={
              config.videoIsVertical ? 'question-video-v' : 'question-video-h'
            }
          >
            {!isVideoActive && (
              <Row
                className="image-content"
                align="middle"
                justify="center"
                onClick={handlePlayVideo}
                children={
                  !isPlayed && (
                    <img
                      className="icon icon__white icon--player icon__size-120"
                      style={{ cursor: 'pointer' }}
                    />
                  )
                }
              />
            )}
            {isVideoFile ? (
              <>
                <video
                  height="100%"
                  width="100%"
                  id={domElementID}
                  onClick={handleCompleteModule}
                  className={`challengeVideo__video ${
                    optionalImage ? 'with-optional-image' : ''
                  }`}
                  preload="metadata"
                >
                  <source src={`${url}#t=0.1`} type="video/mp4" />
                </video>
                {seeImage && optionalImage && (
                  <img
                    className="video-placeholder"
                    loading="lazy"
                    src={optionalImage}
                  />
                )}
              </>
            ) : (
              <>
                <iframe
                  height="100%"
                  width="100%"
                  allowFullScreen
                  src={embedVideoUrl}
                  id={domElementID}
                  style={{ border: 'none' }}
                />
                {!isVideoActive && (
                  <img
                    loading="lazy"
                    className="youtube-placeholder"
                    src={optionalImage || videoThumbnail}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Row>
      {isVideoFile && (
        <Row className="challengeVideo__containerButton" justify="center">
          <a
            className="challengeVideo__containerButton--button link"
            href={url}
            download
          >
            <Button
              className="btn-ghost btn-ghost--black"
              onClick={handleCompleteModule}
            >
              <Row justify="space-between" align="middle">
                <FormattedMessage id="button.download" />
                <a className={`icon icon--download`} />
              </Row>
            </Button>
          </a>
        </Row>
      )}
    </Row>
  );

  return (
    <ChallengeResourceWrapper
      bottomLabel={
        points !== 0 &&
        isCompleted && {
          label: `page.challenge.congratulations-video-${
            isVideoFile ? 'completed' : 'watched'
          }`,
          style: 'completed',
        }
      }
      children={component}
      description={config.description}
      icon={config.icon}
      points={
        points !== 0 &&
        formatMessage(
          { id: 'page.challenge.plus-sign-{points}-points' },
          { points: formatNumber(points) }
        )
      }
      styleClass="challengeDetail--Video"
      title={title}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ChallengeVideo);
