import {
  base_esES,
  deDE,
  enUS,
  esAR,
  esCL,
  esCO,
  esES,
  esGT,
  esMX,
  esPE,
  itIT,
  ptBR,
  ptPT,
} from '../messages';

const getAltLiterals = (mainLanguage, altLanguage) => {
  if (!mainLanguage || !altLanguage) return;

  return { ...mainLanguage, ...altLanguage };
};

const localeProviderMap = new Map<string, { [key: string]: string }>([
  ['es-ES', getAltLiterals(base_esES, esES)],
  ['es-AR', getAltLiterals(base_esES, esAR)],
  ['es-CL', getAltLiterals(base_esES, esCL)],
  ['es-CO', getAltLiterals(base_esES, esCO)],
  ['es-MX', getAltLiterals(base_esES, esMX)],
  ['es-PE', getAltLiterals(base_esES, esPE)],
  ['es-GT', getAltLiterals(base_esES, esGT)],
  ['de-DE', deDE],
  ['en-US', enUS],
  ['it-IT', itIT],
  ['pt-BR', ptBR],
  ['pt-PT', ptPT],
]);

export const intlLocaleProvider = (locale: string) => {
  const intlLocale = localeProviderMap.get(locale);
  return intlLocale;
};
