import React from 'react';
import {
  useSize,
  YuButton,
  YuFlex,
  YuIcon,
  YuModal,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import { getDataCall, postDataCall } from '../../../services/api';
import apiPaths from '../../../services/apiPaths';
import { UserTermsAndConditionsTypeEnum } from '../../shared/enums/termAndConditions.enum';
import { useIntl } from 'react-intl';
import { customFormatMessageValues } from 'utils';
import { addToast } from '../../actions/toastActions';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootReducers } from '../../reducers';
import { useRouter } from 'next/router';
import _ from 'lodash';

interface OwnProps {
  canShow: boolean;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const TermAndConditionsModalComponent = ({
  canShow,
  user,
  addToast,
}: Props) => {
  const size = useSize();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [sending, setSending] = React.useState<boolean>(false);

  const { formatMessage } = useIntl();
  const { query } = useRouter();

  const load = async () => {
    const {
      data: { userTermAndCondition },
    } = await getDataCall({
      dataPath:
        // Timestamp force no navigator cache
        apiPaths.TERM_AND_CONDITIONS.FIND_BY_TYPE.replace(
          '{{TYPE}}',
          UserTermsAndConditionsTypeEnum.TC_2024_04.toString()
        ) +
        '?timestamp=' +
        new Date().getTime(),
      callConfig: {},
    });

    if (!userTermAndCondition) setShowModal(true);
  };

  const send = async () => {
    try {
      setSending(true);
      await postDataCall({
        dataPath: apiPaths.TERM_AND_CONDITIONS.ACCEPT.replace(
          '{{TYPE}}',
          UserTermsAndConditionsTypeEnum.TC_2024_04.toString()
        ),
        data: {},
        callConfig: {},
      });
      setShowModal(false);
    } catch (e) {
      addToast({
        message: formatMessage({ id: 'app.generic-error' }),
        status: 'error',
      });
    } finally {
      setSending(false);
    }
  };

  React.useEffect(() => {
    if (!user) {
      setShowModal(false);
    } else {
      load();
    }
  }, [user?.idUser]);

  if (
    !canShow ||
    (_.isString(query?.page) &&
      ['/terms-conditions', '/privacy-policy', '/cookie-policy'].includes(
        query.page.toString()
      ))
  )
    return null;
  return (
    <div className="term-and-conditions-modal">
      <YuModal
        behaviour={{
          close: { onClickOutArea: false },
        }}
        isOpen={showModal}
        closable={false}
        fullScreen={false}
        size="M"
      >
        <YuFlex flexDirection="column" justify="center" align="center">
          <YuIcon name="DocumentSigned" size="XL" />
          <YuSpacer size="XS" />
          <YuTitle
            size="XS"
            center
            style={{ padding: size.downS ? '0px 10px' : '0px 40px' }}
          >
            {formatMessage({ id: 'modal.term-and-conditions.title' })}
          </YuTitle>
          <YuSpacer size="XS" />
          <YuParagraph size="S">
            {formatMessage(
              { id: 'modal.term-and-conditions.terms' },
              customFormatMessageValues
            )}
          </YuParagraph>
          <YuSpacer size="S" />
          <YuButton onClick={send} prependLoading={sending}>
            {formatMessage({ id: 'modal.term-and-conditions.button' })}
          </YuButton>
        </YuFlex>
      </YuModal>
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ addToast }, dispatch);

const TermAndConditionsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermAndConditionsModalComponent);

export { TermAndConditionsModal };
