import { PlatformUserStatusEnum } from 'src/shared/enums';
import { customFormatMessage } from 'utils';
import { IUser } from 'src/shared/models';
import { IToast, ToastStatusEnum } from 'src/shared/models/toast.model';

export const getToastProps = (user: IUser): IToast | undefined => {
  let toastProps: IToast = undefined;

  switch (user.userStatus) {
    case PlatformUserStatusEnum.PENDING_FIRST_LOGIN:
      toastProps = {} as IToast;
      toastProps.status = ToastStatusEnum.SUCCESS;
      toastProps.message = customFormatMessage(
        'user-status.toast.validation-pending-{name}',
        {
          name: user?.userMainData?.name,
        }
      );

      toastProps.id = 'pending-first-login';
      toastProps.oneTime = true;
      break;
    case PlatformUserStatusEnum.GRANT_FIRST_LOGIN:
      toastProps = {} as IToast;
      toastProps.status = ToastStatusEnum.SUCCESS;
      toastProps.message = customFormatMessage('user-status.toast.validated');
      toastProps.id = 'grant-first-login';
      toastProps.oneTime = true;
      break;
    default:
      return undefined;
  }

  return toastProps;
};
