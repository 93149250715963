import { useEffect, useRef } from 'react';
import * as typeformEmbed from '@typeform/embed';
import { config } from 'config/config';
import { NextPage } from 'next';
import { IUser } from 'src/shared/models';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { usePageName } from 'utils';

interface OwnProps {
  user: IUser | null;
}

const TypeformNPS: NextPage<OwnProps> = ({ user }): JSX.Element => {
  const typeformRef = useRef(null);
  const { formatMessage } = useIntl();
  const router = useRouter();
  const pageName: string | null = usePageName(router);
  const pageNameText = formatMessage({ id: pageName });
  const idTypeFormNPS = config.APP.ID_TYPEFORM_NPS;

  useEffect(() => {
    if (typeformRef.current && user) {
      typeformEmbed.createWidget(
        `${config.APP.TYPEFORM_URL}/${idTypeFormNPS}?typeform-welcome=0#id_usuario=${user.uuidUser}&context=${pageNameText}&user_type=${user.idSpecialty.name}`,
        {
          container: typeformRef.current,
          hideFooter: true,
          hideHeaders: true,
          opacity: 0,
        }
      );
    }
  }, [user, typeformRef.current]);

  return (
    <>
      <div className="typeFormNPS" ref={typeformRef}></div>
    </>
  );
};

export default TypeformNPS;
