import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import {
  CampaignModuleStatusEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
} from 'src/shared/enums';
import { IChallengeMaterials, IMaterial } from 'src/shared/models';
import { checkUserStatus } from 'utils';

const { MATERIAL } = ResourceTypeEnum;

const {
  CHALLENGE_MATERIALS_DESCRIPTION,
  MATERIAL_CONTENT,
  MATERIAL_DESCRIPTION,
  MATERIAL_IMAGE,
  MATERIAL_ORDER,
  MATERIAL_TYPE,
} = ResourceTypeDetailEnum;

export const buildChallengeMaterials = (
  resource: IResourceList
): IChallengeMaterials => {
  const { idResource, score, name, i18nList, activate } = resource;

  let challengeMaterials: IChallengeMaterials;
  const materials: IMaterial[] = [];
  let points = score?.points || 0;
  let isCompleted = true;
  let isPartialDone = false;

  resource.resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case CHALLENGE_MATERIALS_DESCRIPTION:
          challengeMaterials = {
            ...challengeMaterials,
            description: value,
          };
          break;
      }
    });

  resource.resourceList
    .filter(({ status }) => status)
    .forEach((resource: IResourceList) => {
      let material: IMaterial;
      switch (resource.idResourceType.idResourceType) {
        case MATERIAL:
          material = buildMaterial(resource);
          points += material.points;
          materials.push(material);
          isCompleted = isCompleted && material.downloaded;
          isPartialDone = isPartialDone || material.downloaded;
          break;
      }
    });

  return {
    ...challengeMaterials,
    isCampaignActivator: activate,
    idResource,
    i18nList,
    title: name,
    points,
    materials: materials.sort(
      ({ order: orderA }, { order: orderB }) => orderA - orderB
    ),
    isCompleted,
    isPartialDone,
    icon: 'campaign-material-download',
  };
};

const buildMaterial = (materialData: IResourceList): IMaterial => {
  const { name, idResource, i18nList, score } = materialData;
  let material: IMaterial;

  materialData.resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case MATERIAL_ORDER:
          material = {
            ...material,
            order: parseInt(value) || 0,
          };
          break;
        case MATERIAL_CONTENT:
          material = {
            ...material,
            content: value,
            href: value,
          };
          break;
        case MATERIAL_TYPE:
          material = {
            ...material,
            type: parseInt(value) || null,
          };
          break;
        case MATERIAL_DESCRIPTION:
          material = {
            ...material,
            description: value,
          };
          break;
        case MATERIAL_IMAGE:
          material = {
            ...material,
            image: value && value !== '' ? value : null,
          };
          break;
      }
    });

  return {
    ...material,
    i18nList,
    idResource,
    name,
    points: score?.points || 0,
    downloaded: checkUserStatus(materialData, [
      CampaignModuleStatusEnum.GENERIC_DONE,
    ]),
  };
};
