import { buildExternalValidator } from 'src/components/challenge/builder/BuilderExternalValidator';
import { builderLegalCheck } from 'src/components/challenge/builder/BuilderLegalCheck';
import {
  IResource,
  IResourceDetailList,
} from 'src/components/challenge/ChallengeInterfaces';
import {
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
  UserResourceEnum,
} from 'src/shared/enums';
import {
  CertifiedCourse_PlatformsEnum,
  IChallengeCertifiedCourse,
} from 'src/shared/models';
import { checkUserStatus } from 'utils';

const { LEGAL_CHECK, EXTERNAL_VALIDATOR } = ResourceTypeEnum;

abstract class ICertifiedCourseFiller {
  constructor(protected readonly detail: IResourceDetailList) {}

  abstract fill(
    certifiedCourse: IChallengeCertifiedCourse
  ): IChallengeCertifiedCourse;
}

class CourseCreditDescription_CertifiedCourseFiller extends ICertifiedCourseFiller {
  fill(certifiedCourse: IChallengeCertifiedCourse): IChallengeCertifiedCourse {
    return {
      ...certifiedCourse,
      creditsDescriptions: this.detail.value,
    };
  }
}

class CourseURL_CertifiedCourseFiller extends ICertifiedCourseFiller {
  fill(certifiedCourse: IChallengeCertifiedCourse): IChallengeCertifiedCourse {
    return {
      ...certifiedCourse,
      courseURL: this.detail.value,
    };
  }
}

class CourseID_CertifiedCourseFiller extends ICertifiedCourseFiller {
  fill(certifiedCourse: IChallengeCertifiedCourse): IChallengeCertifiedCourse {
    return {
      ...certifiedCourse,
      idCourse: this.detail.value,
    };
  }
}

class Speaker_CertifiedCourseFiller extends ICertifiedCourseFiller {
  fill(certifiedCourse: IChallengeCertifiedCourse): IChallengeCertifiedCourse {
    return {
      ...certifiedCourse,
      speaker: this.detail.value,
    };
  }
}

class Platform_CertifiedCourseFiller extends ICertifiedCourseFiller {
  fill(certifiedCourse: IChallengeCertifiedCourse): IChallengeCertifiedCourse {
    return {
      ...certifiedCourse,
      platformType: this.detail.value as CertifiedCourse_PlatformsEnum,
    };
  }
}
class ChallengeCertifiedCourseFactory {
  private readonly certifiedCourseFillers: Map<
    ResourceTypeDetailEnum,
    ICertifiedCourseFiller
  >;

  constructor(detail: IResourceDetailList) {
    this.certifiedCourseFillers = new Map();

    this.certifiedCourseFillers.set(
      ResourceTypeDetailEnum.CERTIFIED_COURSE_CREDIT,
      new CourseCreditDescription_CertifiedCourseFiller(detail)
    );

    this.certifiedCourseFillers.set(
      ResourceTypeDetailEnum.CERTIFIED_COURSE_URL,
      new CourseURL_CertifiedCourseFiller(detail)
    );

    this.certifiedCourseFillers.set(
      ResourceTypeDetailEnum.CERTIFIED_COURSE_SPEAKER,
      new Speaker_CertifiedCourseFiller(detail)
    );

    this.certifiedCourseFillers.set(
      ResourceTypeDetailEnum.CERTIFIED_COURSE_EXTERNAL_PLATFORM_TYPE,
      new Platform_CertifiedCourseFiller(detail)
    );

    this.certifiedCourseFillers.set(
      ResourceTypeDetailEnum.CERTIFIED_COURSE_ID_COURSE,
      new CourseID_CertifiedCourseFiller(detail)
    );
  }

  fill(
    { idResourceTypeD: { idResourceTypeD } }: IResourceDetailList,
    certifiedCourse: IChallengeCertifiedCourse
  ): IChallengeCertifiedCourse {
    const certifiedCourseFiller =
      this.certifiedCourseFillers.get(idResourceTypeD);

    if (certifiedCourseFiller) {
      return certifiedCourseFiller.fill(certifiedCourse);
    }

    return certifiedCourse;
  }
}

class ChallengeCertifiedCourseBuilder {
  public build(resource: IResource): IChallengeCertifiedCourse {
    let certifiedCourse: IChallengeCertifiedCourse;

    resource.resourceDetailList
      .filter((detail) => detail.status)
      .forEach((detail) => {
        const Factory = new ChallengeCertifiedCourseFactory(detail);

        certifiedCourse = Factory.fill(detail, certifiedCourse);
      });

    certifiedCourse.isPartialDone = checkUserStatus(resource, [
      UserResourceEnum.IN_PROGRESS,
    ]);

    resource.resourceList.forEach((_resource) => {
      switch (_resource.idResourceType.idResourceType) {
        case LEGAL_CHECK:
          certifiedCourse.legalCheck = builderLegalCheck(_resource);
          break;
        case EXTERNAL_VALIDATOR:
          certifiedCourse.externalValidator = buildExternalValidator(_resource);
          break;
      }
    });

    certifiedCourse.isCorrectlyConfigured =
      !!certifiedCourse.legalCheck && !!certifiedCourse.externalValidator;

    return {
      ...certifiedCourse,
      title: resource.name,
    };
  }
}

export const builderCertifiedCourse = (
  resource: IResource
): IChallengeCertifiedCourse => {
  const builder = new ChallengeCertifiedCourseBuilder();

  return builder.build(resource);
};
