import { BackTop, Button, Col, Layout, Row } from 'antd';
import { NextPage } from 'next';
import routePaths from 'config/routes';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootReducers } from 'src/reducers';
import { UserStatusEnum } from 'src/shared/enums/user.enum';
import BasicFooter from './BasicFooter';
import BasicHeader from './BasicHeader';
import MyBagMenu from 'src/components/redeemPoints/myBagMenu';
import CustomModal from 'src/components/CustomModal';
import { config } from 'config/config';
import { FormattedMessage } from 'react-intl';
import {
  ICommercialDisclaimerDTO,
  IDisclaimerDTO,
  IUser,
} from 'src/shared/models';
import {
  styled,
  YuFAB,
  YuToast,
  YuToastContainer,
} from '@isdin/yuma-react-web-pin';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { removeToastById } from '../../actions/toastActions';
import { IToast } from '../../shared/models/toast.model';
import { useHeaderHeight } from '../../hooks/useHeaderHeight';
import ConditionalRenderer from '../ConditionalRenderer';
import { fetchCommercialSubscriptionRecruitmentDate } from 'src/actions/appActions';
import ModalPlatformCommunications from '../modalPlatformCommunications/modalPlatformCommunications';
import { TermAndConditionsModal } from './TermAndConditionsModal';

const {
  ACCOUNT_NOT_VALIDATED,
  MANAGER_NOT_VALIDATED,
  NO_CENTER,
  HCP_NOT_VALIDATED,
  STAFF_NOT_VALIDATED,
} = UserStatusEnum;

const YuFABPosition = styled.div`
  position: fixed;
  right: 66px;
  bottom: 106px;

  @media (min-width: 769px) {
    bottom: 106px;
  }
`;

const YuFABPositionAuthenticated = styled.div`
  position: fixed;
  right: 66px;
  bottom: 122px;

  @media (min-width: 769px) {
    bottom: 106px;
  }
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface IClassNameSuffixCalculator {
  showHeader: boolean;
  impersonate: boolean;
  userStatus: UserStatusEnum;
  user: IUser;
  commercialDisclaimers: ICommercialDisclaimerDTO[];
  showPrivateDisclaimer: boolean;
  showPublicDisclaimers: boolean;
}

const CompatibleBrowsersContent = ({ setOpenCompatibleBrowsersModal }) => {
  return (
    <div>
      <h3 className="browsers-modal__title">
        <FormattedMessage id="browsers-modal.title" />
      </h3>
      <p className="browsers-modal__text">
        <FormattedMessage id="browsers-modal.text" />
      </p>
      <Row className="browsers-modal__icons-container">
        {['IE 10', 'Chrome', 'Firefox', 'Safari', 'Opera'].map((_browser) => (
          <Col xs={7} md={3}>
            <img
              src={`${config.APP.ASSET_PREFIX}/${_browser
                .replace(/\s+/g, '')
                .toLowerCase()}.png`}
              className="browsers-modal__icons"
            />
            <p>{_browser}</p>
          </Col>
        ))}
      </Row>
      <Button
        onClick={() => setOpenCompatibleBrowsersModal(false)}
        className="btn-primary btn-primary--black"
      >
        <FormattedMessage id="browsers-modal.button" />
      </Button>
    </div>
  );
};

const CompatibleBrowsersModal = ({
  openCompatibleBrowsersModal,
  setOpenCompatibleBrowsersModal,
}) => {
  return (
    <CustomModal
      {...{
        content: (
          <CompatibleBrowsersContent
            setOpenCompatibleBrowsersModal={setOpenCompatibleBrowsersModal}
          />
        ),
        hideFooter: true,
        visible: openCompatibleBrowsersModal,
        onCancel: () => setOpenCompatibleBrowsersModal(false),
        showIcon: false,
        customClass: 'browsers-modal',
        centered: true,
      }}
    />
  );
};

const hasActualRouteOperationalDisclaimer = (
  disclaimers: IDisclaimerDTO[],
  route: string
): boolean => {
  const routeHasDisclaimer = disclaimers.some((disclaimer) => {
    return disclaimer.slugsOnApply.some((slug) => {
      const isRouteValid = route === slug;
      const isRouteValidAndChild =
        route.includes(slug) && disclaimer.applyToChilds;
      return isRouteValid || isRouteValidAndChild;
    });
  });

  return routeHasDisclaimer;
};

const BasicLayout: NextPage<Props, unknown> = ({
  user,
  userStatus,
  children,
  impersonate,
  staticPageConfig,
  isPreview,
  commercialDisclaimers,
  privateDisclaimers,
  publicDisclaimers,
  toasts,
  removeToastById,
}) => {
  const { route } = useRouter();
  const [openCompatibleBrowsersModal, setOpenCompatibleBrowsersModal] =
    useState(false);
  const shouldShowHeader = staticPageConfig?.showHeader;
  const shouldShowFooter = staticPageConfig?.showFooter;
  const showHeader = shouldShowHeader !== undefined ? shouldShowHeader : true;
  const [classNameSuffix, setClassNameSuffix] = useState<string>('');
  const showPrivateDisclaimer = hasActualRouteOperationalDisclaimer(
    privateDisclaimers,
    route
  );
  const showPublicDisclaimers = hasActualRouteOperationalDisclaimer(
    publicDisclaimers,
    route
  );

  const headerHeight = useHeaderHeight();

  const showFooter = (route: string) => {
    switch (route) {
      case routePaths.PAGES.MY_ACCOUNT.MY_PROFILE:
      case routePaths.PAGES.MY_ACCOUNT.HISTORY:
      case routePaths.PAGES.MY_ACCOUNT.MY_POINTS:
      case routePaths.PAGES.MY_ACCOUNT.ORDERS:
      case routePaths.PAGES.MY_ACCOUNT_EDIT:
      case routePaths.PAGES.MY_ACCOUNT.MY_ADDRESSES:
      case routePaths.PAGES.MY_CENTER.CENTER_PROFILE:
      case routePaths.PAGES.MY_CENTER.CENTER_MEMBERS:
        return true;
      default:
        return shouldShowFooter !== undefined ? shouldShowFooter : false;
    }
  };

  const addPageClass = (page: string): string => {
    const path = page.replace('/', '');
    if (!path) {
      return 'main';
    }

    if (path.includes('/')) {
      const slashIndex = path.indexOf('/');
      return path.substring(0, slashIndex);
    }

    return page.replace('/', '');
  };

  const getClassNameSuffix = ({
    showHeader,
    impersonate,
    userStatus,
    user,
    commercialDisclaimers,
    showPrivateDisclaimer,
    showPublicDisclaimers,
  }: IClassNameSuffixCalculator) => {
    let suffix = '';

    if (!showHeader) {
      suffix = 'layout__content__without-header';
    } else if (impersonate) {
      suffix = 'layout__content__impersonate';
    }
    if (
      commercialDisclaimers.length ||
      [
        ACCOUNT_NOT_VALIDATED,
        MANAGER_NOT_VALIDATED,
        NO_CENTER,
        HCP_NOT_VALIDATED,
        STAFF_NOT_VALIDATED,
      ].includes(userStatus)
    ) {
      if (showPrivateDisclaimer) {
        suffix += ' layout__content__dual-disclaimer';
      } else {
        suffix += ' layout__content__disclaimer';
      }
    } else if (showPrivateDisclaimer) {
      suffix += ' layout__content__disclaimer';
      // eslint-disable-next-line no-dupe-else-if
    } else if (commercialDisclaimers.length) {
      suffix += ' layout__content__commercial-disclaimer';
    }

    if (user) suffix += ' layout__content__logged';
    else if (showPublicDisclaimers)
      suffix = ' layout__content__public_disclaimer';

    return suffix;
  };

  useEffect(() => {
    const suffix = getClassNameSuffix({
      showHeader,
      impersonate,
      userStatus,
      user,
      commercialDisclaimers,
      showPrivateDisclaimer,
      showPublicDisclaimers,
    });
    setClassNameSuffix(suffix);
  }, [
    showHeader,
    impersonate,
    userStatus,
    user,
    commercialDisclaimers,
    showPrivateDisclaimer,
    showPublicDisclaimers,
  ]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isIE =
      !!window.MSInputMethodContext ||
      navigator?.appName == 'Microsoft Internet Explorer' ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !!document.documentMode;
    if (isIE) setOpenCompatibleBrowsersModal(true);
  }, []);

  return (
    <Layout
      className={`basic-layout ${addPageClass(route)} ${
        isPreview ? 'previewMode' : ''
      }`}
    >
      <ConditionalRenderer
        condition={!!user}
        trueComponent={<ModalPlatformCommunications />}
      />

      <YuToastContainer
        offset={{
          top: 0,
        }}
      >
        {toasts.map((toast: IToast) => {
          return (
            <YuToast
              key={toast.id}
              handleOnRemove={() => removeToastById(toast.id)}
              handleOnTimeout={() => removeToastById(toast.id)}
              open
              status={toast.status}
            >
              {toast.message}
            </YuToast>
          );
        })}
      </YuToastContainer>
      <TermAndConditionsModal canShow={showHeader} />
      {showHeader && <BasicHeader />}
      <Layout.Content
        style={{ marginTop: headerHeight + 'px' }}
        className={`layout__content ${classNameSuffix}`}
      >
        {children}
        {user && <MyBagMenu />}
        <BackTop>
          <ConditionalRenderer
            condition={!!user}
            trueComponent={
              <YuFABPositionAuthenticated>
                <YuFAB variant="arrowUp" />
              </YuFABPositionAuthenticated>
            }
            falseComponent={
              <YuFABPosition>
                <YuFAB variant="arrowUp" />
              </YuFABPosition>
            }
          />
        </BackTop>
      </Layout.Content>
      <BasicFooter showFooter={showFooter(route)} />
      <CompatibleBrowsersModal
        openCompatibleBrowsersModal={openCompatibleBrowsersModal}
        setOpenCompatibleBrowsersModal={setOpenCompatibleBrowsersModal}
      />
    </Layout>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    toasts: state.toast.toasts,
    user: state.auth.user,
    accountCreateDate: state.auth.user?.createDate,
    impersonate: state.auth.impersonate,
    userStatus: state.auth.userStatus,
    staticPageConfig: state.app.staticPageConfig,
    isPreview: state.auth.isPreview,
    commercialDisclaimers: state.app.disclaimers.commercial,
    privateDisclaimers: state.app.disclaimers.private,
    publicDisclaimers: state.app.disclaimers.public,
    commercialSubscriptionRecruitment:
      state.app.commercialSubscriptionRecruitment,
    userCommercialSubscriptionStatus:
      state.auth.user?.salesforce.mailSubscription.status,
    userCommercialSubscriptionDate:
      state.auth.user?.salesforce.mailSubscription.subscriptionDate,
    userCommercialUnsubscriptionDate:
      state.auth.user?.salesforce.mailSubscription.unsubscriptionDate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { removeToastById, fetchCommercialSubscriptionRecruitmentDate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BasicLayout);
