import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import {
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
  UserResourceEnum,
} from 'src/shared/enums';
import { IExternalRedeemPoints } from 'src/shared/models/challengeExternalRedeemPoints.model';
import { buildChallengeExternalRedeemPointsPoints } from './BuilderExternalRedeemPointsPoints';
import { ExternalRedeemPointsType } from '../externalRedeemPoints/ExternalRedeemPoints.enum';
import { checkUserStatus } from 'utils';

const {
  EXTERNAL_REDEEM_POINTS_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_TYPE,
  EXTERNAL_REDEEM_POINTS_BUTTON_LABEL,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_BUTTON_LABEL,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_BUTTON_LABEL,
} = ResourceTypeDetailEnum;

const { DONE } = UserResourceEnum;

export const buildExternalRedeemPoints = (params: {
  resource: IResourceList;
  availableUserPoints: number;
}): IExternalRedeemPoints => {
  const { resource, availableUserPoints } = params;
  const {
    resourceDetailList,
    idResource,
    name: title,
    resourceList,
  } = resource;

  let externalRedeemPoints: IExternalRedeemPoints;

  const externalRedeemPointsPointsList = resourceList
    .filter(
      ({ idResourceType }) =>
        idResourceType.idResourceType ===
        ResourceTypeEnum.EXTERNAL_REDEEM_POINTS_POINTS
    )
    .map((resource) =>
      buildChallengeExternalRedeemPointsPoints({
        resource,
        minimPointsToRender: availableUserPoints,
      })
    );

  resourceDetailList
    .filter(({ status }) => status)
    .forEach((_item) => {
      switch (_item.idResourceTypeD.idResourceTypeD) {
        case EXTERNAL_REDEEM_POINTS_DESCRIPTION:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            description: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_TYPE:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            type: _item.value as ExternalRedeemPointsType,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_BUTTON_LABEL:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            buttonLabel: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            tcModalDescription: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            tcModalFile: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_TC_MODAL_BUTTON_LABEL:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            tcModalButtonLabel: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            completeModalTitle: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_DESCRIPTION:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            completeModalDescription: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            completeModalWarning: _item.value,
          };
          break;
        case EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_BUTTON_LABEL:
          externalRedeemPoints = {
            ...externalRedeemPoints,
            completeModalButtonLabel: _item.value,
          };
          break;
      }
    });

  const isCompleted = resource.resourceList.find((resource) =>
    checkUserStatus(resource, [DONE])
  );

  externalRedeemPoints = {
    ...externalRedeemPoints,
    title: title,
    icon: 'puntos',
    idResource: idResource,
    pointsToRedeemList: externalRedeemPointsPointsList,
    isCompleted: Boolean(isCompleted),
  };

  return externalRedeemPoints;
};
