import { InfoCircleOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import routePaths, { deprecatedRoutePaths } from 'config/routes';
import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootReducers } from 'src/reducers';
import { IDisclaimerDTO } from '../../shared/models';
import { useIntl } from 'react-intl';
import { customFormatMessage } from 'utils';

interface OwnProps {
  pathname: string;
  extraDisclaimerName?: string;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const MIN_SCROLL_POSITION = 120;

const Disclaimer: NextPage<Props, unknown> = ({
  privateDisclaimers,
  publicDisclaimers,
  isPrivatePage,
  pathname,
  maxSaleRestrictions,
  extraDisclaimerName,
}): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { formatMessage } = useIntl();
  let prevScrollPos: number;

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    let visible = true;

    switch (true) {
      // Scroll UP
      case prevScrollPos > currentScrollPos:
        visible = true;
        break;
      // Scroll DOWN
      case currentScrollPos > prevScrollPos:
        visible = currentScrollPos <= MIN_SCROLL_POSITION;
        break;
    }
    prevScrollPos = currentScrollPos;
    setIsVisible(visible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const maxAnnualProductsDisclaimer = {
    title: 'maxAnualProductsDisclaimer',
    message: customFormatMessage(
      'page.redeem-points.disclaimer.message-{maxAnnualProducts}',
      {
        maxAnnualProducts:
          maxSaleRestrictions?.annual.baseMaxTotalProductsByPeriod,
        units: formatMessage({
          id:
            Number(maxSaleRestrictions?.annual.baseMaxTotalProductsByPeriod) > 1
              ? 'page.redeem-points.errors.units'
              : 'page.redeem-points.errors.unit',
        }),
      }
    ),
    applyToChilds: true,
    slugsOnApply: [],
  };
  const findNearestDisclaimer = (): IDisclaimerDTO | undefined => {
    const disclaimers = isPrivatePage ? privateDisclaimers : publicDisclaimers;
    const slug = pathname;
    let nearestDisclaimer: IDisclaimerDTO | undefined;
    let maxMatchLength = 0;
    if (extraDisclaimerName === 'maxAnnualProducts') {
      return maxAnnualProductsDisclaimer;
    }
    disclaimers
      .filter((disclaimer) => {
        const applyOnChilds =
          disclaimer.applyToChilds &&
          disclaimer.slugsOnApply.some((slug) => pathname.startsWith(slug));

        return applyOnChilds || disclaimer.slugsOnApply.includes(pathname);
      })
      .forEach((disclaimer) => {
        disclaimer.slugsOnApply.forEach((appliedSlug) => {
          if (
            slug.startsWith(appliedSlug) &&
            appliedSlug.length > maxMatchLength
          ) {
            maxMatchLength = appliedSlug.length;
            nearestDisclaimer = disclaimer;
          }
        });
      });

    return nearestDisclaimer;
  };

  const disclaimerToShow: IDisclaimerDTO = findNearestDisclaimer();

  if (!disclaimerToShow) return <React.Fragment />;

  return (
    <Row
      align="middle"
      justify="center"
      className={`disclaimer ${isPrivatePage ? '' : ' public'}${
        isVisible ? '' : ' disclaimer--hidden'
      } `}
    >
      <span>
        <InfoCircleOutlined className="disclaimer__icon" />
        {disclaimerToShow?.message}
      </span>
    </Row>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  const userIsLogged = !!state.auth.user;

  return {
    privateDisclaimers: state.app.disclaimers.private,
    publicDisclaimers: state.app.disclaimers.public,
    isPrivatePage: userIsLogged,
    maxSaleRestrictions:
      state.auth.user?.magento.orderRestrictions.salesRestrictions
        .maxSaleRestrictions,
    redeemedProductsByTime:
      state.auth.user?.magento.orderRestrictions.redeemedProductsByTime,
  };
};
export default connect(mapStateToProps, null)(Disclaimer);

export const mapLegacyURLs = (
  disclaimers: IDisclaimerDTO[]
): IDisclaimerDTO[] => {
  const legacyURLs = {
    [deprecatedRoutePaths.PAGES.TRAININGS]: routePaths.PAGES.TRAINING,
    [deprecatedRoutePaths.PAGES.TALKS]: routePaths.PAGES.TALK,
  };

  return disclaimers.map((disclaimer) => {
    return {
      ...disclaimer,
      slugsOnApply: disclaimer.slugsOnApply.reduce((acc, slug) => {
        const mapLegacyURL = legacyURLs[slug];

        if (mapLegacyURL) return [...acc, slug, mapLegacyURL];

        return [...acc, slug];
      }, []),
    };
  });
};
