import { IUser } from '../shared/models';
import getConfig from 'next/config';
import { useUserIsDermatologistSpecialty } from './useUserIsDermatologistSpecialty';
import { useShowUserProfileSpecialtyExtraFields } from './useShowUserProfileSpecialtyExtraFields';
import { PlatformUserStatusEnum } from '../shared/enums';

const {
  publicRuntimeConfig: { USE_NEW_REGISTER_FORM },
} = getConfig();

export const useShowCompleteProfile = (user: IUser): boolean => {
  if (USE_NEW_REGISTER_FORM !== 'true') return false;
  if (
    ![
      PlatformUserStatusEnum.GRANT,
      PlatformUserStatusEnum.MIGRATE_GRANT,
      PlatformUserStatusEnum.GRANT_FIRST_LOGIN,
    ].includes(user?.userStatus)
  )
    return false;
  const showUserProfileSpecialtyExtraFields =
    useShowUserProfileSpecialtyExtraFields(user);
  // if showUserProfileSpecialtyExtraFields is true return false because all fields are filled
  if (showUserProfileSpecialtyExtraFields) return false;

  const isDermatologist = useUserIsDermatologistSpecialty(user);

  // Add more
  return isDermatologist; // || isMedic;
};
