import {
  useSize,
  YuDivider,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';

const SkeletonMyOrdersDetails: NextPage = (): JSX.Element => {
  const { upXS, upM } = useSize();

  const DesktopHeaderDescription = (
    <>
    <YuSpacer size="L" />
      <YuFlex
        flexDirection="row"
        maxWidth={'1400px'}
        style={{ margin: 'auto' }}
      >
        <YuSkeletonWrapper
          paddingLeft="L"
          paddingRight="M"
          paddingTop="L"
          minWidth="400px"
          width={400}
          backgroundColor="bg.grey"
        >
          <YuFlex gap="M" flexDirection="column">
            <YuSkeletonWrapper
              width="96%"
              style={{
                borderRadius: '4px',
                boxShadow:
                  '0px 0px 1px 0px #1313134D, 0px 4px 8px 0px #13131333',
              }}
              backgroundColor="bg.grey"
              padding="XS"
            >
              <YuFlex flexDirection="row" gap="L">
                <YuFlex gap="XXS" flexDirection="column">
                  <YuSkeleton height={16} width={71} variant="text" />
                  <YuSkeleton height={24} width={154} variant="text" />
                </YuFlex>
                <YuSkeleton height={56} width={56} variant="text" />
              </YuFlex>
            </YuSkeletonWrapper>
            <YuFlex gap="S" flexDirection="column">
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width={24} variant="text" />
                <YuSkeleton height={16} width={91} variant="text" />
              </YuFlex>
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width={24} variant="text" />
                <YuSkeleton height={16} width={116} variant="text" />
              </YuFlex>
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width={24} variant="text" />
                <YuSkeleton height={16} width={140} variant="text" />
              </YuFlex>
              <YuFlex flexDirection="row" gap="XXS">
                <YuSkeleton height={16} width={24} variant="text" />
                <YuSkeleton height={16} width={116} variant="text" />
              </YuFlex>
            </YuFlex>
          </YuFlex>
        </YuSkeletonWrapper>
        <YuFlex flexDirection="row">
          <YuSkeletonWrapper
            marginTop="S"
            marginBottom="M"
            paddingLeft="XXL"
            paddingTop="L"
            paddingBottom="L"
            width="100%"
            maxWidth="620px"
          >
            <YuFlex gap="M" flexDirection="column">
              <YuSkeleton height={18} width={75} variant="text" />
            </YuFlex>
            <YuSpacer size="L" />
            <YuFlex gap="M" flexDirection="column">
              <YuSkeleton height={34} width={200} variant="text" />
            </YuFlex>
            <YuSpacer size="S" />
            <YuFlex justify="end">
              <YuSkeleton height={32} width={96} variant="text" />
            </YuFlex>
            <YuSpacer size="S" />
            <YuFlex gap="M" flexDirection="column">
              <YuSkeleton height={24} width={150} variant="text" />
            </YuFlex>
            <YuSpacer size="XS" />
            <YuFlex gap="M" flexDirection="column">
              <YuSkeleton height={22} width={164} variant="text" />
            </YuFlex>
            <YuSpacer size="S" />
            <YuDivider style={{ color: '#E1E1E1' }} />
            <YuSpacer size="S" />
            <YuFlex gap="XS" flexDirection="row">
              <YuSkeleton
                height={77}
                width={77}
                variant="text"
                style={{ marginRight: '30px' }}
              />
              <YuFlex flexDirection="column" style={{ marginRight: 'auto' }}>
                <YuSkeleton height={20} width={170} variant="text" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={20} width={120} variant="text" />
                <YuSpacer size="XS" />
                <YuSkeleton height={16} width={80} variant="text" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={16} width={80} variant="text" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={16} width={130} variant="text" />
              </YuFlex>
              <YuSkeleton height={25} width={100} variant="text" />
            </YuFlex>
            <YuSpacer size="S" />
            <YuDivider style={{ color: '#E1E1E1' }} />
            <YuSpacer size="S" />
            <YuFlex gap="M" flexDirection="row" display="flex">
              <YuSkeleton
                height={24}
                width={64}
                variant="text"
                style={{ marginRight: 'auto' }}
              />
              <YuSkeleton height={24} width={120} variant="text" />
            </YuFlex>
            <YuSpacer size="XXL" />
            <YuFlex gap="M" flexDirection="row" display="flex">
              <YuFlex flexDirection="column">
                <YuSkeleton height={20} width={120} variant="text" />
                <YuSpacer size="S" />
                <YuSkeleton height={18} width={180} variant="text" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={18} width={180} variant="text" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={18} width={90} variant="text" />
              </YuFlex>
              <YuFlex flexDirection="column" style={{ marginRight: 'auto' }}>
                <YuSkeleton height={20} width={120} variant="text" />
                <YuSpacer size="S" />
                <YuSkeleton height={18} width={180} variant="text" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={18} width={180} variant="text" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={18} width={90} variant="text" />
              </YuFlex>
            </YuFlex>
          </YuSkeletonWrapper>
        </YuFlex>
      </YuFlex>
    </>
  );

  const TabletHeaderDescription = (
    <>
      <YuSkeletonWrapper
        marginTop="S"
        marginBottom="M"
        paddingTop="L"
        paddingBottom="L"
        width="96%"
        maxWidth="520px"
        style={{ margin: 'auto' }}
      >
        <YuFlex gap="M" flexDirection="column">
          <YuSkeleton height={18} width={75} variant="text" />
        </YuFlex>
        <YuSpacer size="L" />
        <YuFlex gap="M" flexDirection="column">
          <YuSkeleton height={34} width={200} variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        <YuFlex justify="end">
          <YuSkeleton height={32} width={96} variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        <YuFlex gap="M" flexDirection="column">
          <YuSkeleton height={24} width={150} variant="text" />
        </YuFlex>
        <YuSpacer size="XS" />
        <YuFlex gap="M" flexDirection="column">
          <YuSkeleton height={22} width={164} variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        <YuDivider style={{ color: '#E1E1E1' }} />
        <YuSpacer size="S" />
        <YuFlex gap="XS" flexDirection="row">
          <YuSkeleton
            height={77}
            width={77}
            variant="text"
            style={{ marginRight: '30px' }}
          />
          <YuFlex flexDirection="column" style={{ marginRight: 'auto' }}>
            <YuSkeleton height={20} width={170} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={20} width={120} variant="text" />
            <YuSpacer size="XS" />
            <YuSkeleton height={16} width={80} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={16} width={80} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={16} width={130} variant="text" />
          </YuFlex>
          <YuSkeleton height={25} width={100} variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        <YuDivider style={{ color: '#E1E1E1' }} />
        <YuSpacer size="S" />
        <YuFlex gap="M" flexDirection="row" display="flex">
          <YuSkeleton
            height={24}
            width={64}
            variant="text"
            style={{ marginRight: 'auto' }}
          />
          <YuSkeleton height={24} width={120} variant="text" />
        </YuFlex>
        <YuSpacer size="XXL" />
        <YuFlex flexDirection="column">
          <YuSkeleton height={20} width={120} variant="text" />
          <YuSpacer size="S" />
          <YuSkeleton height={18} width={180} variant="text" />
          <YuSpacer size="XXS" />
          <YuSkeleton height={18} width={180} variant="text" />
          <YuSpacer size="XXS" />
          <YuSkeleton height={18} width={90} variant="text" />
          <YuSpacer size="M" />
          <YuFlex flexDirection="column" style={{ marginRight: 'auto' }}>
            <YuSkeleton height={20} width={120} variant="text" />
            <YuSpacer size="S" />
            <YuSkeleton height={18} width={180} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={18} width={180} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={18} width={90} variant="text" />
          </YuFlex>
        </YuFlex>
      </YuSkeletonWrapper>
    </>
  );

  const MobileHeaderDescription = (
    <>
      <YuSkeletonWrapper
        marginTop="S"
        width="90%"
        maxWidth="520px"
        style={{ margin: '0 auto auto' }}
      >
        <YuSpacer size="L" />
        <YuFlex gap="M" flexDirection="row">
          <YuSkeleton height={24} width={180} variant="text" />
          <YuSkeleton
            height={20}
            width={20}
            variant="text"
            style={{ marginLeft: 'auto' }}
          />
        </YuFlex>
      </YuSkeletonWrapper>
      <YuSpacer size="L" />
      <YuSkeletonWrapper
        backgroundColor="bg.grey"
        paddingTop="M"
        paddingBottom="S"
        paddingLeft="S"
        paddingRight="S"
      >
        <YuFlex gap="M" flexDirection="column">
          <YuSkeleton height={24} width={150} variant="text" />
        </YuFlex>
        <YuSpacer size="XS" />
        <YuFlex gap="M" flexDirection="column">
          <YuSkeleton height={22} width={164} variant="text" />
        </YuFlex>
      </YuSkeletonWrapper>
      <YuSpacer size="S" />
      <YuSkeletonWrapper width="90%" style={{ margin: '0 auto auto' }}>
        <YuFlex gap="XS" flexDirection="row">
          <YuSkeleton height={77} width={77} variant="text" />
          <YuFlex flexDirection="column" style={{ marginRight: 'auto' }}>
            <YuSkeleton height={20} width={120} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={20} width={90} variant="text" />
            <YuSpacer size="XS" />
            <YuSkeleton height={16} width={60} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={16} width={60} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={16} width={100} variant="text" />
          </YuFlex>
          <YuFlex flexDirection="column" justify="end">
            <YuSkeleton height={25} width={100} variant="text" />
          </YuFlex>
        </YuFlex>
      </YuSkeletonWrapper>
      <YuSpacer size="S" />
      <YuDivider style={{ color: '#E1E1E1' }} />
      <YuSpacer size="S" />
      <YuSkeletonWrapper width="90%" style={{ margin: '0 auto auto' }}>
        <YuFlex gap="M" flexDirection="row" display="flex">
          <YuSkeleton
            height={24}
            width={64}
            variant="text"
            style={{ marginRight: 'auto' }}
          />
          <YuSkeleton height={24} width={120} variant="text" />
        </YuFlex>
      </YuSkeletonWrapper>
      <YuSpacer size="L" />
      <YuSkeletonWrapper
        backgroundColor="bg.grey"
        paddingTop="L"
        paddingBottom="S"
        paddingLeft="S"
        paddingRight="S"
      >
        <YuFlex flexDirection="column">
          <YuSkeleton height={20} width={120} variant="text" />
          <YuSpacer size="S" />
          <YuSkeleton height={18} width={180} variant="text" />
          <YuSpacer size="XXS" />
          <YuSkeleton height={18} width={180} variant="text" />
          <YuSpacer size="XXS" />
          <YuSkeleton height={18} width={90} variant="text" />
          <YuSpacer size="M" />
          <YuFlex flexDirection="column" style={{ marginRight: 'auto' }}>
            <YuSkeleton height={20} width={120} variant="text" />
            <YuSpacer size="S" />
            <YuSkeleton height={18} width={180} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={18} width={180} variant="text" />
            <YuSpacer size="XXS" />
            <YuSkeleton height={18} width={90} variant="text" />
          </YuFlex>
        </YuFlex>
      </YuSkeletonWrapper>
    </>
  );

  return upM
    ? DesktopHeaderDescription
    : upXS
    ? TabletHeaderDescription
    : MobileHeaderDescription;
};

export default SkeletonMyOrdersDetails;
