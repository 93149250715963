import {
  useSize,
  YuCol,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';

const ScientificMaterials: NextPage = (): JSX.Element => {
  const { upXS, upL, upM } = useSize();
  const CARDS_NUMBER = 2;

  const skeletonCard = () => {
    return (
      <YuFlex
        flexDirection="column"
        className="card"
        style={{ width: upXS ? 'auto' : '100%' }}
      >
        <YuSkeleton
          className="top-rounded-borders"
          variant="rectangular"
          width={{ initial: '100%', upXS: 390 }}
          height={{ initial: 260, upXS: 260 }}
        />
        <YuSpacer size={upM ? 'M' : 'L'} />
        <YuCol className="description">
          <YuSkeleton variant="text" height={24} width="100%" />
          <YuSpacer size="XXS" />
          <YuSkeleton variant="text" height={24} width="80%" />
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuSkeleton variant="text" width="100%" />
          <YuSpacer size="XXS" />
          <YuSkeleton variant="text" width="100%" />
          <YuSpacer size="XXS" />
          <YuSkeleton variant="text" width="90%" />
        </YuCol>
        <YuSpacer size={upM ? 'M' : 'L'} />
      </YuFlex>
    );
  };

  return (
    <>
      <YuSkeletonWrapper className="yuma-custom-skeleton container">
        <YuFlex flexDirection="column">
          <YuFlex
            flexDirection="column"
            ml={{ onlyXS: 'S', upXS: 'S' }}
            mr={{ onlyXS: 'S', upXS: 'S' }}
          >
            <YuSpacer size="L" />
            <YuSkeleton
              height={40}
              variant="text"
              width={upXS ? '292px' : '100%'}
            />
            <YuSpacer size="M" />
            <YuSkeleton height={20} variant="text" width={'100px'} />
          </YuFlex>

          {_.times(4, () => {
            return (
              <YuFlex
                flexDirection="column"
                pl={{ onlyXS: 'S', upXS: 'M' }}
                pr={{ onlyXS: 'S', upXS: 'M' }}
              >
                <YuSpacer size="XL" />
                <YuSkeleton height={30} variant="text" width={'200px'} />
                <YuSpacer size="XS" />
                <YuSkeleton height={1} variant="text" width={'100%'} />
                <YuSpacer size="S" />
                <YuFlex gap={upL ? 'L' : 'M'} wrap="wrap" justify="start">
                  {_.times(CARDS_NUMBER, () => skeletonCard())}
                </YuFlex>
              </YuFlex>
            );
          })}

          <YuSpacer size="XL" />
        </YuFlex>
      </YuSkeletonWrapper>
    </>
  );
};

export default ScientificMaterials;
