import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { putDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { fetchStates } from 'src/actions/appActions';
import { updateUserData } from 'src/actions/authActions';
import CustomModal from 'src/components/CustomModal';
import AddressSalesforceForm from 'src/components/forms/AddressSalesforceForm';
import { ShoppingCartUserAddressModel } from 'src/components/shoppingCart/ShoppingCart.model';
import { createOrEditAddress } from 'src/components/shoppingCart/ShoppingCart.utils';
import { IRootReducers } from 'src/reducers';
import { ICustomer, ISalesforceAddress, IState } from 'src/shared/models';
import {
  Checkout__Shipping,
  getSalesforceEmptyAddress,
  isUSAPlatform,
  renderAddressTitle,
  telephoneLengthValidation,
} from 'utils';
import { CustomCloseIcon } from './ShoppingCart';
import { commonModalConfig } from '../redeemPoints/checkout/checkout.utils';
import { Store } from 'antd/lib/form/interface';

interface OwnProps {
  showCheckoutModal: boolean;
  setShowCheckoutModal: React.Dispatch<boolean>;
  customer: ICustomer;
  stateList: IState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMagentoData: any;
  userCountry: string;
  handleOrderProducts: (data: string) => unknown;
  setSelectedAddress: React.Dispatch<ISalesforceAddress>;
  selectedAddress: ISalesforceAddress;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const AddressModal: FC<Props> = ({
  showCheckoutModal,
  setShowCheckoutModal,
  customer,
  stateList,
  updateMagentoData,
  userCountry,
  handleOrderProducts,
  setSelectedAddress,
  selectedAddress,
  salesforceCenter,
  salesforceAddresses,
  fetchStates,
  updateUserData,
  user,
}) => {
  const [addressToEdit, setAddressToEdit] = useState<ISalesforceAddress>(null);
  const [showNewAddressModal, setShowNewAddressModal] =
    useState<boolean>(false);
  const [newUserPhone, setNewUserPhone] = useState<string>(
    user.salesforce.userPhone ?? null
  );
  const { formatMessage } = useIntl();
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = Form.useForm();

  const handleCreateAddress = async (data) => {
    const [address, response] = await createOrEditAddress(data, false, {
      customer,
      updateMagentoData,
      stateList,
      userCountry,
      formatMessage,
    });

    setSelectedAddress(address);

    if (response) return await handleOrderProducts(address.id);
  };

  const handleCreateOrder = async (addressId: Store) => {
    const { userName, userFirstname, userSurname, userMail, userPhone } =
      user.salesforce;
    setModalLoading(true);
    const userAddressUsa: Partial<ShoppingCartUserAddressModel> = {
      name: userFirstname,
      surName: userSurname,
      email: userMail,
      userName,
      userFirstname,
      userSurname,
      userMail,
      userPhone: newUserPhone,
      passwordHash: user.password,
      updateOnCheckout: true,
    };

    const userAddress: Partial<ShoppingCartUserAddressModel> = {
      ...userAddressUsa,
      userDocumentID: user.salesforce.userDocumentID,
    };

    if (newUserPhone !== userPhone) {
      await putDataCall({
        dataPath: apiPaths.PROFILE.UPDATE_USER_INFO,
        data: isUSAPlatform ? userAddressUsa : userAddress,
        callConfig: {},
      });

      user.salesforce.userPhone = newUserPhone;
      updateUserData(user);
    }

    if (showNewAddressModal && isUSAPlatform) {
      return await handleCreateAddress(addressId);
    }
    return await handleOrderProducts(null);
  };

  useEffect(() => {
    if (!isUSAPlatform) setSelectedAddress(salesforceAddresses[0]);
    if (!stateList) (async () => fetchStates())();
  }, []);

  const closeAddress = () => {
    setShowNewAddressModal(false);
    setAddressToEdit(null);
  };

  const loadingSpinner = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: 'red',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        verticalAlign: 'center',
      }}
      spin
    />
  );

  return (
    // TODO Use CustomModal
    <>
      <Modal
        visible={showCheckoutModal}
        footer={null}
        onCancel={() => setShowCheckoutModal(false)}
        className="shopping-cart-modal buy-modal"
        closeIcon={<CustomCloseIcon />}
        centered
        wrapClassName="buy-modal-wrapper checkout-modal-wrapper"
      >
        {showCheckoutModal && (
          <>
            <Row
              justify="start"
              align="middle"
              className="buy-modal__title-select"
            >
              {formatMessage({ id: 'shopping-cart.buy-modal.title' })}
            </Row>
            {modalLoading ? (
              <Row className="shopping-cart-spiner" justify="center">
                <Spin
                  className="shopping-cart-spiner__container"
                  indicator={loadingSpinner}
                />
              </Row>
            ) : (
              <div className="challengeDetail__description--extended">
                <Form
                  form={form}
                  name="buy-materials"
                  layout="vertical"
                  className="buy-materials-form"
                  onFinish={handleCreateOrder}
                >
                  <Row className="buy-materials-form__address-Container">
                    <Col span={24}>
                      <div className="buy-materials-form__address-options">
                        <Checkout__Shipping
                          addresses={salesforceAddresses}
                          canCreateNew={true}
                          onSelectAddress={(address: ISalesforceAddress) => {
                            setShowNewAddressModal(false);
                            setSelectedAddress(address);
                          }}
                          selectedAddress={selectedAddress}
                          // selectedAddress={selectedAddress || customer?.addresses[0]}
                          onCreateNewAddress={() => {
                            setShowNewAddressModal(true);
                            setSelectedAddress(null);
                          }}
                          salesforceCenter={salesforceCenter}
                          onEditAddress={setAddressToEdit}
                          userPhone={newUserPhone}
                          setUserPhone={setNewUserPhone}
                          isChallengeShoppingCart={true}
                        />
                      </div>
                      <Button
                        htmlType="submit"
                        className={`btn-primary btn-primary--black shopping-cart__send-button ${
                          (!selectedAddress ||
                            showNewAddressModal ||
                            isUSAPlatform) &&
                          'btn-disabled'
                        }`}
                        block
                        disabled={
                          !selectedAddress ||
                          showNewAddressModal ||
                          !newUserPhone ||
                          isUSAPlatform ||
                          !telephoneLengthValidation(newUserPhone)
                        }
                      >
                        {showNewAddressModal ? (
                          <FormattedMessage id="shopping-cart.modal.add-and-request" />
                        ) : (
                          <FormattedMessage id="shopping-cart.modal.request-materials" />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </>
        )}
      </Modal>
      <CustomModal
        visible={showNewAddressModal || addressToEdit !== null}
        title={renderAddressTitle({
          closeAddress,
          formatMessage,
          editAddress: false,
        })}
        centered
        content={
          <Col className="create-address-form" span={24}>
            <AddressSalesforceForm
              {...{
                userCountry,
                addressSelected: showNewAddressModal
                  ? getSalesforceEmptyAddress(user)
                  : addressToEdit,
                closeAddress,
                isNew: showNewAddressModal,
                onUpdate: updateUserData,
                stateList,
                user,
              }}
            />
          </Col>
        }
        {...commonModalConfig}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      fetchStates,
      updateUserData,
    },
    dispatch
  );

const mapStateToProps = (state: IRootReducers) => {
  return {
    salesforceAddresses: state.auth.user.salesforce.addresses,
    salesforceCenter: state.app.salesforceCenter,
    stateList: state.app.stateList,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressModal);
