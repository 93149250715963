import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';

const SkeletonPartialRedeemPoints: NextPage = (): JSX.Element => {
  const { upXS, upM, upS } = useSize();
  const CARDS_NUMBER_DESKTOP = 3;
  const CARD_NUMBER_TABLET = 2;
  const CARD_NUMBER_MOBILE = 1;

  const CARD_FILE_NUMBERS_DESKTOP = 3;
  const CARD_FILE_NUMBERS_TABLET = 4;
  const CARD_FILE_NUMBER_MOBILE = 9;

  const CARD_FILE_NUMBERS = upM
    ? CARD_FILE_NUMBERS_DESKTOP
    : upS
    ? CARD_FILE_NUMBERS_TABLET
    : CARD_FILE_NUMBER_MOBILE;

  const CARD_NUMBER = upS
    ? CARDS_NUMBER_DESKTOP
    : upXS
    ? CARD_NUMBER_TABLET
    : CARD_NUMBER_MOBILE;
  const skeletonCard = () => {
    return (
      <YuFlex
        flexDirection="column"
        style={{ width: upXS ? 'auto' : '100%', margin: 'auto' }}
      >
        <YuSkeleton
          variant="rectangular"
          width={{ initial: 200, upXS: 300, upXL: 393 }}
          height={{ initial: 200, upXS: 420 }}
        />
        <YuFlex flexDirection="column" align={'center'}>
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuSkeleton variant="text" height={24} width="100%" />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" height={22} width="80%" />
          <YuSpacer size="XS" />
          <YuSkeleton variant="text" height={25} width="120px" />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" height={28} width="130px" />
          <YuSpacer size={upM ? 'M' : 'L'} />
        </YuFlex>
      </YuFlex>
    );
  };

  return (
    <>
      <YuSkeletonWrapper className="yuma-custom-skeleton">
        <YuFlex flexDirection="column">
          {_.times(CARD_FILE_NUMBERS, () => {
            return (
              <YuFlex flexDirection="column" justify="center">
                <YuFlex
                  pl={{ onlyXS: 'S', upXS: 'S' }}
                  pr={{ onlyXS: 'S', upXS: 'S' }}
                  justify="center"
                >
                  <YuFlex
                    gap={{
                      upXS: 'S',
                      upS: 'XS',
                      upM: 'S',
                      upL: 'XXL',
                      upXL: 'M',
                    }}
                    wrap="wrap"
                    justify="start"
                  >
                    {_.times(CARD_NUMBER, () => skeletonCard())}
                  </YuFlex>
                </YuFlex>
              </YuFlex>
            );
          })}
          <YuSpacer size="M" />
          <YuFlex justify={'center'}>
            <YuSkeleton height={36} variant="text" width={'133px'} />
          </YuFlex>
          <YuSpacer size="XL" />
        </YuFlex>
      </YuSkeletonWrapper>
    </>
  );
};

export default SkeletonPartialRedeemPoints;
