import { RightOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { Button, Col, Drawer, Row } from 'antd';
import routePaths, { deprecatedRoutePaths } from 'config/routes';
import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { showBagMenu } from 'src/actions/appActions';
import { getItemLink } from 'src/components/layout/BasicMenu';
import Link from 'src/components/Link';
import { IRootReducers } from 'src/reducers';
import { IMenu } from 'src/shared/models';
import {
  CheckOutCart,
  getTranslatedData,
  redeemPointStatus,
  requestOrderStatus,
} from 'utils';
import PlatformSelector from '../platformSelector/PlatformSelector';
import { YuFlex, YuIcon, YuLogoSecondary } from '@isdin/yuma-react-web-pin';
import redirectTo from 'services/redirectTo';

const { CONTACT_US } = routePaths.FOOTER;
const { TERMS, PRIVACY_POLICY } = deprecatedRoutePaths.FOOTER;
const { LOGIN, SIGNUP, MY_ACCOUNT } = routePaths.PAGES;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

interface OwnProps {
  loggedMenu: IMenu[];
}

const DrawerMenu: NextPage<Props, unknown> = ({
  cartItems,
  impersonate,
  loggedMenu,
  user,
  userStatus,
  isLoggingIn,
  showBagMenu,
  userPoints,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const [nItemsCart, setnItemsCart] = useState(0);
  const [collapsed, setCollapse] = useState(false);
  const path = useRouter();
  const { pathname } = useRouter();

  const handleCollapse = (): void => {
    setCollapse(!collapsed);
  };
  const isButtonHiden = (buttonName: string) => {
    if (pathname === routePaths.INDEX) return false;

    return pathname
      .toLocaleLowerCase()
      .includes(buttonName.toLocaleLowerCase());
  };
  useEffect(() => {
    let qty = 0;
    cartItems.map((item) => {
      qty += item.qty;
    });
    setnItemsCart(qty);
  }, [cartItems]);

  const appBar = (): JSX.Element => (
    <Row
      justify="center"
      align="middle"
      style={{ justifyContent: 'space-between', margin: user ? '0 26px' : '' }}
    >
      {user && (
        <YuFlex width="80px">
          <YuIcon name="MenuBurger" onClick={handleCollapse} size="L" />
        </YuFlex>
      )}

      {!user && (
        <div className="selector-login__wrapper">
          <a
            onClick={() => {
              redirectTo(routePaths.INDEX);
            }}
          >
            <YuLogoSecondary size="S"></YuLogoSecondary>
          </a>
          <YuFlex gap={'XS'}>
            <PlatformSelector right={isButtonHiden('login')} />
            <Link href={routePaths.PAGES.LOGIN} as="/login">
              <Button
                type="primary"
                className={`btn-primary btn-primary--white--small ${
                  isButtonHiden('login') ? 'btn-hidden' : ''
                }`}
                disabled={isLoggingIn}
              >
                <FormattedMessage id="app.sign-in.header" />
              </Button>
            </Link>
          </YuFlex>
        </div>
      )}

      {user && (
        <>
          <div
            onClick={() => {
              redirectTo(routePaths.INDEX);
            }}
          >
            <YuLogoSecondary size="S"></YuLogoSecondary>
          </div>
          <Row
            style={{ width: 'auto', gap: '68.5px', justifyContent: 'flex-end' }}
          >
            <YuFlex gap={'S'} width="80px" justify={'end'}>
              <YuIcon
                name="User"
                size="L"
                onClick={() =>
                  redirectTo(routePaths.PAGES.MY_ACCOUNT.ACCOUNT_INDEX)
                }
              />

              <CheckOutCart
                user={user}
                showBagMenu={showBagMenu}
                cartItems={nItemsCart}
                path={path}
                enabled={redeemPointStatus.includes(userStatus)}
              />
            </YuFlex>
          </Row>
        </>
      )}
    </Row>
  );

  const buildUserAction = (
    goto: string,
    label: string,
    showBorder: boolean
  ): JSX.Element => {
    const classBorder = showBorder ? 'container-label__with-border' : '';

    return (
      !user && (
        <Link href={goto}>
          <Col span={12} onClick={handleCollapse}>
            <Row
              align="middle"
              className={`container-label ${classBorder}`}
              justify="center"
            >
              <span className="container-text">
                <FormattedMessage id={label} />
              </span>
            </Row>
          </Col>
        </Link>
      )
    );
  };

  const buildFooterLinkSpecial = (goto: string, label: string): JSX.Element => {
    return (
      <Link href={`/static?page=${goto}`} as={goto} replace>
        <Row className="link-to-static-pages" onClick={handleCollapse}>
          <FormattedMessage id={label} />
        </Row>
      </Link>
    );
  };

  const buildFooterLink = (goto: string, label: string): JSX.Element => {
    return (
      <Link href={goto}>
        <Row className="link-to-static-pages" onClick={handleCollapse}>
          <FormattedMessage id={label} />
        </Row>
      </Link>
    );
  };

  const welcomeMessage = !user && (
    <Row className="welcome-message">
      <h2>
        <FormattedMessage id="app.welcome" />
      </h2>
      <Row className="regular-body" style={{ paddingBottom: '1cm' }}>
        <FormattedMessage id="app.welcome-description-create-account" />
      </Row>
      <Row className="regular-body">
        <FormattedMessage id="app.welcome-description-sign-in" />
      </Row>
    </Row>
  );

  const renderMenuItems = (): JSX.Element[] =>
    loggedMenu
      .sort((a: IMenu, b: IMenu) => a.order - b.order)
      .map((item: IMenu): JSX.Element => {
        if (!item.visible) return;

        const disabled =
          item.route === routePaths.PAGES.REQUEST_ORDER &&
          !requestOrderStatus.includes(userStatus)
            ? true
            : item.disabled || false;

        const _buttonDetails = (
          <Row
            className={`logged-menu-button ${disabled ? 'disabled' : ''}`}
            onClick={handleCollapse}
            justify="space-between"
            align="middle"
          >
            {getTranslatedData(item, 'content') || item.name}
            <RightOutlined />
          </Row>
        );

        return item.absolute ? (
          <a
            key={item.idMenu}
            href={item.route}
            className={item.disabled ? 'disabled' : ''}
          >
            {_buttonDetails}
          </a>
        ) : (
          <Link href={getItemLink(item.route)} key={item.route || item.idMenu}>
            {_buttonDetails}
          </Link>
        );
      });

  const gotoMyAccount = (): JSX.Element =>
    user && (
      <Link href={MY_ACCOUNT.ACCOUNT_INDEX}>
        <Row
          onClick={handleCollapse}
          justify="space-between"
          className="logged-menu-button-my-account"
          align="middle"
        >
          <Col>
            <Row className="text-label">
              <span className="label-text">
                <FormattedMessage id="app.my-account" />
              </span>
            </Row>
            <Row>
              <p className="secondary-text secondary-text__schedule">
                {formatMessage(
                  { id: 'app.you-have-x-points' },
                  { points: userPoints }
                )}
              </p>
            </Row>
          </Col>
          <RightOutlined />
        </Row>
      </Link>
    );

  const drawerClassName = 'drawer-menu';

  useEffect(() => {
    document.body.classList.toggle('not-overflow', collapsed);
  }, [collapsed]);

  return (
    <>
      {appBar()}

      <Drawer
        className={drawerClassName}
        placement="left"
        closable={false}
        visible={collapsed}
        destroyOnClose
        onClose={handleCollapse}
        maskClosable
        zIndex={50000}
      >
        <div className="close-icon">
          <YuIcon name="Cross" size="S" onClick={handleCollapse} />
        </div>
        <div className="drawer-body">
          {welcomeMessage}
          {renderMenuItems()}
        </div>

        <div
          className={`drawer-footer ${
            impersonate ? 'drawer-footer__impersonate' : ''
          }`}
        >
          <Row className="container-user-actions" align="middle">
            {buildUserAction(LOGIN, 'app.sign-in', true)}
            {buildUserAction(SIGNUP, 'app.register', false)}
            {gotoMyAccount()}
          </Row>
          {buildFooterLink(CONTACT_US, 'menu.section.contact')}
          {buildFooterLinkSpecial(TERMS, 'app.terms')}
          {buildFooterLinkSpecial(PRIVACY_POLICY, 'app.privacy-policy')}
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    userStatus: state.auth.userStatus,
    impersonate: state.auth.impersonate,
    cartItems: state.app.redeemPoints.cart.items,
    isLoggingIn: state.app.isLoggingIn,
    userPoints: state?.auth?.userPoints,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ showBagMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DrawerMenu);
