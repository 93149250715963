export interface ICustomer {
  id: number;
  group_id: number;
  default_billing: string;
  created_at: string;
  updated_at: string;
  created_in: string;
  email: string;
  firstname: string;
  lastname: string;
  middlename: string;
  prefix: string;
  store_id: number;
  website_id: number;
  addresses: ICustomerAddress[];
  disable_auto_group_change: number;
  extension_attributes: {
    is_subscribed: boolean;
  };
  custom_attributes: ICustomAttribute[];
  orderRestrictions: IOrderRestrictions;
}

export interface IOrderRestrictions {
  redeemedProductsByTime?: IOrderRestrictionsByTime;
  salesRestrictions?: IMagentoCatalogLimits;
}

export interface IOrderRestrictionsByTime {
  annual?: string;
  monthly?: string;
}

export interface ICustomerAddress {
  id?: number;
  customer_id: number;
  region?: {
    region_code: string;
    region: string;
    region_id: string;
  };
  country_id: string;
  street: string[];
  telephone: string;
  postcode: string;
  city: string;
  firstname: string;
  lastname: string;
  middlename: string;
  prefix: string;
  default_shipping?: boolean;
  default_billing?: boolean;
}

interface ICustomAttribute {
  attribute_code: string;
  value: null | string;
}

export interface IMagentoCatalogLimits {
  maxSaleRestrictions: IMagentoCatalogLimit;
  validOrderTypes: string;
}

export interface IMagentoCatalogLimit {
  annual?: {
    period: string;
    baseMaxTotalProductsByPeriod: string;
    maxSpecificProductByPeriod: string;
    maxTotalProductsByPeriod: string;
    notificationThreshold: string;
    customDateFormat: string;
  };
  monthly?: {
    period: string;
    baseMaxTotalProductsByPeriod: string;
    maxSpecificProductByPeriod: string;
    maxTotalProductsByPeriod: string;
    notificationThreshold: string;
    customDateFormat: string;
  };
}

export interface IBrand {
  label: string;
  value: string;
  isChecked?: boolean;
}

// FIXME: no se utiliza, borrar??. Se utiliza IFilterItem
export interface IPathology {
  label: string;
  value: string;
  isChecked?: boolean;
}

// FIXME: BORRAR TODO LO RELACIONADO CON categories
export interface ICategory {
  value: number;
  description: string;
}

export const defaultOrderRestrictions: IOrderRestrictions = {
  salesRestrictions: {
    validOrderTypes: '',
    maxSaleRestrictions: {
      annual: {
        period: '',
        baseMaxTotalProductsByPeriod: '9999',
        maxSpecificProductByPeriod: '9999',
        maxTotalProductsByPeriod: '9999',
        notificationThreshold: '100',
        customDateFormat: '',
      },
    },
  },
  redeemedProductsByTime: {
    annual: '0',
  },
};
