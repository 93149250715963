import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';

const SkeletonMyPoints: NextPage = (): JSX.Element => {
  const { upM, upS, downM } = useSize();

  return (
    <YuSkeletonWrapper style={{ maxWidth: '1370px', margin: 'auto' }}>
      <YuSpacer size="M" />
      <YuFlex flexDirection={upS ? 'row' : 'column'}>
        <ConditionalRenderer
          condition={upM}
          trueComponent={
            <YuSkeletonWrapper
              marginTop="S"
              paddingLeft="L"
              paddingRight="M"
              paddingTop="L"
              minWidth="400px"
              width="400px"
              backgroundColor="bg.grey"
            >
              <YuFlex gap="M" flexDirection="column">
                <YuSkeletonWrapper
                  style={{
                    boxShadow:
                      '0px 0px 1px 0px #1313134D, 0px 4px 8px 0px #13131333',
                  }}
                  maxWidth="1400px"
                  width="96%"
                  borderRadius="4px"
                  backgroundColor="bg.grey"
                  padding="XS"
                >
                  <YuFlex flexDirection="row" gap="L">
                    <YuFlex gap="XXS" flexDirection="column">
                      <YuSkeleton height={16} width={71} />
                      <YuSkeleton height={24} width={154} />
                    </YuFlex>
                    <YuSkeleton height={56} width="56px" />
                  </YuFlex>
                </YuSkeletonWrapper>
                <YuFlex gap="S" flexDirection="column">
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width={24} />
                    <YuSkeleton height={16} width={91} />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width={24} />
                    <YuSkeleton height={16} width={116} />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width={24} />
                    <YuSkeleton height={16} width="140px" />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width={24} />
                    <YuSkeleton height={16} width={116} />
                  </YuFlex>
                </YuFlex>
              </YuFlex>
            </YuSkeletonWrapper>
          }
        />

        <YuSkeletonWrapper
          paddingLeft={upM ? 'L' : 0}
          width="100%"
          alignItems={upM ? 'left' : 'center'}
        >
          <ConditionalRenderer
            condition={downM}
            trueComponent={
              <>
                <YuSkeleton
                  height={24}
                  width={150}
                  variant="text"
                  alignSelf="start"
                  ml="XS"
                />

                <YuSpacer size="M" />
              </>
            }
          />
          <YuFlex
            gap="M"
            flexDirection="column"
            align={{ initial: 'center', upM: 'start' }}
            mt={{ initial: 0, upS: 'M' }}
            ml={{ initial: 0, upS: 'L' }}
          >
            <YuSpacer size="XXXS" />
            <div>
              <YuSkeleton height={32} width={150} maxWidth="100%" />
              <YuSpacer size={downM ? 'L' : 'M'} />
            </div>       
            <YuSkeleton height={16} width={230} />
            <YuSkeleton height={50} width={260} />
            <YuSpacer size="L" />
            <YuSkeleton height={25} width={150} />
            <YuSkeleton height={16} width={230} />
            <YuSkeleton height={50} width={350} />
            <YuSpacer size="L" />
            <YuSkeleton height={25} width={350} />
            <YuSkeleton height={25} width={100} />
            <YuFlex
              flexDirection={upM ? 'row' : 'column'}
              gap="M"
              wrap="wrap"
              align={{ initial: 'center', upS: 'start' }}
              justify={{ initial: 'center', upS: 'start' }}
              width="100%"
            >
              <YuSkeleton height={50} width={upM ? 260 : 350} />
              <YuSkeleton height={50} width={upM ? 260 : 350} />
            </YuFlex>
          </YuFlex>
        </YuSkeletonWrapper>
      </YuFlex>
      <YuSpacer size="XL" />
    </YuSkeletonWrapper>
  );
};

export default SkeletonMyPoints;
