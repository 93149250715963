import { IUser } from '../shared/models';
import getConfig from 'next/config';
import _ from 'lodash';
import { PlatformUserStatusEnum } from '../shared/enums';

const {
  publicRuntimeConfig: { USE_NEW_REGISTER_FORM },
} = getConfig();

export const useShowUserProfileSpecialtyExtraFields = (user: IUser): boolean => {
  if (USE_NEW_REGISTER_FORM !== 'true') return false;
  if (
    ![
      PlatformUserStatusEnum.GRANT,
      PlatformUserStatusEnum.MIGRATE_GRANT,
      PlatformUserStatusEnum.GRANT_FIRST_LOGIN,
    ].includes(user?.userStatus)
  )
    return false;
  return (
    !!user?.birthday &&
    !!user?.currentPosition &&
    !_.isEmpty(user?.specialtyAreas)
  );
};
