import { Card, Col, Row } from 'antd';
import { NextPage } from 'next';
import React from 'react';

const { Meta } = Card;

interface OwnProps {
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  cardClass?: string;
  cardTitle?: string;
  cardTitleIcon?: React.ReactNode;
  cardDescription?: string;
  metaTitle?: string;
  metaDescription: string | React.ReactNode;
}

const CustomCard: NextPage<OwnProps, unknown> = ({
  flexDirection,
  cardClass,
  cardTitle,
  cardTitleIcon,
  cardDescription,
  metaTitle,
  metaDescription,
}): JSX.Element => {
  const componentTitle =
    !!cardTitle || !!cardDescription ? (
      <Row justify="center" align="middle" className="block--small">
        <h3 style={{ fontWeight: 600 }}>{cardTitle}</h3>
        <p className="regular-body">{cardDescription}</p>
      </Row>
    ) : null;

  return (
    <Col className="customCard">
      {componentTitle}
      <Row justify="center" align="middle">
        <Card
          style={{ flexDirection }}
          className={`customCard__card ${cardClass} customCard__${flexDirection}`}
          cover={cardTitleIcon}
        >
          <Meta
            className="customCard__meta regular-body"
            title={metaTitle}
            description={metaDescription}
          />
        </Card>
      </Row>
    </Col>
  );
};

export default CustomCard;
