import { Button, Row } from 'antd';
import React, { FC, useState, Fragment } from 'react';
import {
  IChallengeActivationButton,
  IChallengeResourceRender,
} from 'src/shared/models';
import { FormattedMessage } from 'react-intl';
import { completeChallengeUserResource } from 'utils';
import ChallengeLegalCheck from './ChallengeLegalCheck';
import { postDataCall, putDataCall } from 'services/api';

interface OwnProps extends IChallengeResourceRender {
  config: IChallengeActivationButton;
  idResource: number;
  id: string;
  shouldRenderChallengeHeader: boolean;
}

const ChallengeActivationButton: FC<OwnProps> = ({
  config,
  idResource,
  shouldRenderChallengeHeader,
  updateUserPoints,
  updateChallenge,
  id,
}): JSX.Element => {
  if (!config) return <React.Fragment />;
  const {
    isCompleted,
    title,
    legalCheck,
    callBack,
    shouldRenderInChallengeHeader,
  } = config;
  const acceptedCheck = legalCheck ? legalCheck.wasAccepted : false;
  const [errorCallback, setErrorCallback] = useState<boolean>(false);
  const [legalCheckData, setLegalCheckData] = useState<{
    isAccepted: boolean;
    idResource: number;
  }>({
    idResource: 0,
    isAccepted: acceptedCheck,
  });
  const isInHeaderHidden =
    !shouldRenderChallengeHeader && shouldRenderInChallengeHeader;

  const handelcallback = async () => {
    try {
      switch (callBack.requestType) {
        case 'post':
          await postDataCall({
            dataPath: callBack.requestUrl,
            data: {},
            callConfig: {},
          });
          setErrorCallback(false);
          break;
        case 'put':
          await putDataCall({
            dataPath: callBack.requestUrl,
            data: {},
            callConfig: {},
          });
          setErrorCallback(false);
          break;
        default:
          break;
      }
    } catch (error) {
      setErrorCallback(true);
      return error;
    }
    completeChallengeUserResource({
      idResource,
      updateChallenge,
      updateUserPoints,
    });
  };
  return (
    !isInHeaderHidden && (
      <Row
        justify={shouldRenderInChallengeHeader ? 'start' : 'center'}
        className={
          shouldRenderInChallengeHeader ? '' : 'activation-button__resource'
        }
      >
        {legalCheck && (
          <Row justify="start">
            <ChallengeLegalCheck
              {...{
                isCompleted: isCompleted,
                config: legalCheck,
                updateLegalData: setLegalCheckData,
              }}
            />
          </Row>
        )}
        {isCompleted ? (
          <Row
            className={`activate-campaign-confirm-label ${
              shouldRenderInChallengeHeader
                ? ''
                : 'activate-campaign-confirm-label-not-header'
            }`}
            justify="center"
            align="middle"
          >
            <FormattedMessage
              id={
                callBack.onCompleteMessage ||
                'page.campaign.activate-button.success'
              }
            />
          </Row>
        ) : errorCallback && shouldRenderInChallengeHeader ? (
          <Fragment />
        ) : (
          <Button
            disabled={legalCheck && !legalCheckData.isAccepted}
            onClick={() => {
              handelcallback();
            }}
            type="primary"
            id={id}
            className={`btn-primary btn-primary--black`}
          >
            {title}
          </Button>
        )}
        {errorCallback && (
          <Row justify={shouldRenderInChallengeHeader ? 'start' : 'center'}>
            <Row
              className={`activate-campaign-confirm-label  activate-campaign-confirm-label-error
            `}
              justify="center"
              align="middle"
            >
              <FormattedMessage id="page.campaign.activate-button.error" />
            </Row>
          </Row>
        )}
      </Row>
    )
  );
};

export default ChallengeActivationButton;
