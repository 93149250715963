import routePaths from 'config/routes';
import { DateTime } from 'luxon';
import { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { privateRoute } from 'services/privateRoute';
import {
  fetchTrainings,
  insertChallenges,
  updateChallenges,
  updateFilters,
} from 'src/actions/appActions';
import { EmptyList } from 'src/components';
import { builderChallenge } from 'src/components/challenge/builder/BuilderChallenge';
import BuildPageChallenge, {
  showMoreButton,
} from 'src/components/challenge/ChallengeBuildPage';
import ChallengeCard from 'src/components/challenge/ChallengeCard';
import DistributorFilter from 'src/components/distributorElements/filter/DistributorFilter';
import SubHeader from 'src/components/SubHeader';
import TypeformNPS from 'src/components/typeforms/nps/TypeFormNPS';
import { IRootReducers } from 'src/reducers';
import {
  ChallengedTabsEnum,
  ChallengeTypeEnum,
  ResourceTypeEnum,
} from 'src/shared/enums';
import {
  ChallengeCardButton,
  ChallengeDetail,
  IChallenge,
  IChallengeQuiz,
  IChallengesList,
  IFilter,
  IResponseChallengesList,
} from 'src/shared/models';
import { ReduxPageContext } from 'src/store';
import {
  fetchInitialData,
  getTranslatedData,
  isUSAPlatform,
  prepareChallengeFiltersResult,
  renderChallengeCard,
} from 'utils';
import { getTimeZone } from 'utils/timeUtils';

import { fetchFilters } from '../../adapters/fetchFilters';

const PAGE_SIZE = 4;

interface OwnProps {
  initialData: IChallenge[];
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const renderChallengeTraining = (
  challenge: ChallengeDetail,
  isLinkedChallenge?: boolean
): JSX.Element => {
  const { formatMessage } = useIntl();
  const { challengeDates, challengeResources } = challenge;

  const challengeQuiz: IChallengeQuiz = challengeResources.find(
    ({ type }) => ResourceTypeEnum.QUIZ === type
  )?.config;

  let challengeCardButton: ChallengeCardButton = {
    className: '',
    isDisabled: false,
    path: routePaths.PAGES.TRAINING_DETAIL,
    title: formatMessage({ id: 'training.see' }),
  };

  switch (true) {
    case !challengeDates.startDate:
    case !challengeDates.endDate:
      challengeCardButton = {
        ...challengeCardButton,
        title: formatMessage({ id: 'training.not-available' }),
        className:
          'challengeCard__link challengeCard__link--inactive challengeCard__link--blocked',
      };
      break;
    case challengeQuiz?.isQuizDone:
      challengeCardButton = {
        ...challengeCardButton,
        title: formatMessage({ id: 'training.repeat' }),
        className: 'challengeCard__link challengeCard__link--active',
      };
      break;

    case DateTime.now().setZone(getTimeZone()) <
      DateTime.fromJSDate(new Date(challengeDates.startDate)):
    case DateTime.now().setZone(getTimeZone()) >
      DateTime.fromJSDate(new Date(challengeDates.startDate)):
      challengeCardButton = {
        ...challengeCardButton,
        title: formatMessage({ id: 'training.see' }),
        className: 'challengeCard__link challengeCard__link--active',
      };
      break;
  }

  const config: ChallengeDetail = {
    ...challenge,
    challengeName: getTranslatedData(challenge, 'name'),
    challengeDescription:
      getTranslatedData(challenge, 'description') ||
      getTranslatedData(challenge, 'descriptionLong'),
    challengeCardButton,
    isLinkedChallenge,
  };

  return <ChallengeCard {...config} />;
};

const TrainingPage: NextPage<Props> = ({
  fetchTrainings,
  savedFilters,
  totalChallenges,
  trainingsList,
  updateChallenges,
  user,
}): JSX.Element => {
  const getCurrentListPage = () => Math.floor(trainingsList.length / PAGE_SIZE);

  const [trainings, setTrainings] = useState<ChallengeDetail[]>();
  const [filters, setFilters] = useState<ChallengedTabsEnum>(
    ChallengedTabsEnum.ALL
  );

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [requestPage, setRequestPage] = useState<number>(getCurrentListPage);
  const [canShowMore, setCanShowMore] = useState<boolean>(
    totalChallenges > trainingsList.length
  );
  const [selectedFilters, setSelectedFilters] = useState<string>(
    prepareChallengeFiltersResult(savedFilters)
  );
  const [displayFilters, setDisplayFilters] = useState<IFilter[]>(savedFilters);

  const handleShowMore = async () => {
    setIsLoadingMore(true);

    const _categories = '';

    // TODO: Add categorias filtradas
    // if (requestCategories.length > 0) {
    //   _categories = `&categories=${requestCategories.join()}`;
    // }
    const request = `${apiPaths.CHALLENGES.GET_TRAININGS}?page=${requestPage}&size=${PAGE_SIZE}${_categories}${selectedFilters}`;
    try {
      const response = await getDataCall({
        dataPath: request,
        callConfig: {},
      });

      const ids = new Set(
        trainingsList.map((training) => training.challengeId)
      );
      const newchallengeAcc: ChallengeDetail[] = [
        ...trainingsList,
        ...response.data.challenges
          .filter((training: IChallenge) => !ids.has(training.idChallenge))
          .map((challenge: IChallenge) => builderChallenge(challenge, user)),
      ];

      updateChallenges({
        challengeType: 'trainings',
        challengeList: {
          total: response.data.total,
          challenges: newchallengeAcc,
        },
      });

      setRequestPage(requestPage + 1);
      const challengesLeft = totalChallenges - trainingsList.length;
      setCanShowMore(challengesLeft > response.data.challenges.length);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderTrainings = () => {
    let filteredTrainings: ChallengeDetail[] = [];
    const customMessage =
      filters === ChallengedTabsEnum.COMPLETED
        ? 'page.training.no-completed-results'
        : null;

    if (!trainings?.length)
      return <EmptyList page_name="trainings" customMessage={customMessage} />;

    if (trainings && trainings.length) {
      switch (filters) {
        case ChallengedTabsEnum.PENDING:
          filteredTrainings = trainings.filter((_event) => {
            const { isChallengeInLife, isQuizDone } =
              _event.headboardConfiguration;
            return !isQuizDone && isChallengeInLife;
          });
          break;
        // TODO: use isCampaignCompleted and align
        case ChallengedTabsEnum.COMPLETED:
          filteredTrainings = trainings.filter(
            ({ challengeResources }) =>
              challengeResources.find(
                ({ type }) => ResourceTypeEnum.QUIZ === type
              )?.config.isQuizDone
          );
          break;
        case ChallengedTabsEnum.FAVORITES:
          filteredTrainings = trainings.filter(
            (_event) => _event.challengeLikes.liked
          );
          break;
        default:
          filteredTrainings = trainings;
          break;
      }
    }

    if (!filteredTrainings.length)
      return <EmptyList page_name="trainings" customMessage={customMessage} />;

    return filteredTrainings.map((challenge) =>
      renderChallengeCard({ challenge })
    );
  };

  useEffect(() => {
    if (isUSAPlatform)
      trainingsList.sort((trainingA, trainingB) => {
        if (trainingA.isFeatured === trainingB.isFeatured) {
          return trainingB.dateValues.startDate > trainingA.dateValues.startDate
            ? -1
            : 1;
        }
        return trainingA.isFeatured ? -1 : 1;
      });

    setTrainings(trainingsList);
    setCanShowMore(totalChallenges > trainingsList.length);
  }, [trainingsList]);

  useEffect(() => {
    setDisplayFilters(savedFilters);
  }, [savedFilters.length]);

  useEffect(() => {
    fetchTrainings(user, [], trainingsList.length);
  }, []);

  const handleFilter = (filters: IFilter[]) => {
    setSelectedFilters(prepareChallengeFiltersResult(filters));
    setRequestPage(2);

    fetchTrainings(user, filters, trainingsList.length);
  };

  const FiltersComponent = (): JSX.Element => {
    return <DistributorFilter filters={displayFilters} onDone={handleFilter} />;
  };

  const showMoreComponent =
    filters == ChallengedTabsEnum.ALL
      ? showMoreButton(
          `page.marketing-materials.show-more`,
          isLoadingMore,
          canShowMore,
          handleShowMore
        )
      : null;

  return (
    <>
      <SubHeader />
      <BuildPageChallenge
        pageClassName="trainingPage"
        onChangeTab={setFilters}
        renderChallenges={renderTrainings}
        filterComponent={<FiltersComponent />}
        showMoreComponent={showMoreComponent}
        shouldRenderTabs={trainings?.length > 0}
        tabs={[
          { key: ChallengedTabsEnum.ALL, label: 'app.all' },
          { key: ChallengedTabsEnum.PENDING, label: 'app.pending' },
          { key: ChallengedTabsEnum.COMPLETED, label: 'app.completed' },
          { key: ChallengedTabsEnum.FAVORITES, label: 'app.my-favourites' },
        ]}
        challengeType={ChallengeTypeEnum.FORMATION}
      />
      <div className="ant-row">
        <TypeformNPS user={user} />
      </div>
    </>
  );
};

const initialAction = async (
  accessToken: string,
  ctx: ReduxPageContext
): Promise<{ data: unknown }> => {
  const state: IRootReducers = ctx.store.getState();

  const savedTrainings = state.app.challenges.trainings;
  const filtersLoaded = state.app.challenges.trainings.filtersLoaded;

  let filterList: IFilter[] = [];

  let trainings: IChallengesList = {
    total: savedTrainings.total,
    challenges: savedTrainings.challenges,
    filters: savedTrainings.filters,
    filtersLoaded: savedTrainings.filtersLoaded,
  };

  try {
    const areLoadedChallenges = savedTrainings.total > 0;

    if (!areLoadedChallenges) {
      const responsePromise: Promise<IResponseChallengesList> =
        fetchInitialData(
          accessToken,
          `${apiPaths.CHALLENGES.GET_TRAININGS}?size=8`,
          ctx
        );

      let filtersPromise: Promise<IFilter[]>;
      if (!filtersLoaded) {
        filtersPromise = fetchFilters(ChallengeTypeEnum.FORMATION);
      } else {
        filtersPromise = Promise.resolve(
          state.app.challenges.trainings.filters
        );
      }

      const [response, filterListResult] = await Promise.all([
        responsePromise,
        filtersPromise,
      ]);

      filterList = filterListResult;

      const trainingList: ChallengeDetail[] = response.challenges.map(
        (challenge) =>
          builderChallenge(challenge, ctx.store.getState().auth.user)
      );

      trainings = {
        total: response.total,
        challenges: trainingList,
        filters: savedTrainings.filters,
        filtersLoaded: false,
      };
    }

    if (ctx.store.dispatch) {
      insertChallenges({
        challengeList: trainings,
        challengeKey: 'trainings',
      })(ctx.store.dispatch);
    }

    if (!filtersLoaded) {
      updateFilters(ctx, ChallengeTypeEnum.FORMATION, filterList);
    }

    return { data: [] };
  } catch (error) {
    console.error('SERVER ERROR: ', error);
    return { data: null };
  }
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    trainingsList: state.app.challenges.trainings.challenges,
    totalChallenges: state.app.challenges.trainings.total,
    savedFilters: state.app.challenges.trainings.filters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateChallenges, fetchTrainings }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  privateRoute({
    WrappedComponent: TrainingPage,
    initialAction,
  })
);
