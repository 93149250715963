import { Row } from 'antd';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Link from './Link';
import routePaths from '../../config/routes';
import { isESPlatform } from 'utils';

export const EmptyListEnum = Object.freeze({
  CAMPAIGNS: 'campaigns',
  CERTIFIED_COURSES: 'certified_courses',
  EVENTS_CONGRESSES: 'eventsCongresses',
  MARKETING_MATERIALS: 'marketing_materials',
  PEARLS: 'pearls',
  REDEEM_POINTS: 'redeem_points',
  SCIENTIFIC_MATERIALS: 'scientific_materials',
  TRAININGS: 'trainings',
});

export const MapPageToIconEnum = Object.freeze({
  CAMPAIGNS: 'icon--campaign-map',
  CERTIFIED_COURSES: 'icon--certified-course',
  EVENTS_CONGRESSES: isESPlatform ? `icon--calendar` : `icon--events`,
  MARKETING_MATERIALS: 'icon--marketing-materials',
  PEARLS: 'icon--medal',
  REDEEM_POINTS: 'icon--gift',
  SCIENTIFIC_MATERIALS: 'icon--marketing-materials',
  TRAININGS: 'icon--challenge',
});

export declare type EmptyListType =
  typeof EmptyListEnum[keyof typeof EmptyListEnum];

export const EmptyList: FC<{
  page_name: EmptyListType;
  customMessage?: string;
  isFiltered?: boolean;
}> = ({ children, page_name, customMessage, isFiltered }) => {
  const _prefix = `${page_name}${isFiltered ? '.filtered' : ''}`;

  const getIcon = (): string => {
    return `icon icon__grey-primary ${
      MapPageToIconEnum[page_name.toUpperCase()]
        ? `${MapPageToIconEnum[page_name.toUpperCase()]} icon__size-85`
        : 'icon--calendar_day icon__size-48'
    }`;
  };

  return (
    <Row className="empty-list container" justify="center">
      <div className="empty-list__container">
        <Row className="empty-list__icon" justify="center">
          <div
            style={{
              marginBottom: MapPageToIconEnum[page_name.toUpperCase()]
                ? '0px'
                : '16px',
            }}
            className={`icon ${getIcon()} `}
          />
        </Row>
        <Row className="empty-list__title" justify="center">
          <FormattedMessage id={`page.empty-list.title.${_prefix}`} />
        </Row>
        <Row className="empty-list__subtitle" justify="center">
          <FormattedMessage
            id={customMessage ?? `page.empty-list.subtitle.${_prefix}`}
          />
        </Row>

        <Row className="empty-list__go-to-main-page" justify="center">
          <Link href={routePaths.INDEX}>
            <span>
              <FormattedMessage id={'page.empty-list.go-to-main-page'} />
            </span>
          </Link>
        </Row>
        {children}
      </div>
    </Row>
  );
};
