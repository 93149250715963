import { Button, Col, Row } from 'antd';
import { default as React, FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { config as configApp } from 'config/config';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import {
  ChallengeDetail,
  IChallenge,
  IChallengeCertifiedCourse,
} from 'src/shared/models';
import { updateChallenges } from '../../actions/appActions';
import ChallengeLegalCheck from '../../components/challenge/ChallengeLegalCheck';
import CustomModal from '../../components/CustomModal';
import { IRootReducers } from '../../reducers';
import { builderChallenge } from './builder';
import { CHALLENGE_TYPE_VALUES } from '../../shared/enums';

interface OwnProps {
  children?: React.ReactNode;
  config: IChallengeCertifiedCourse;
  updateChallenges: {
    challengeType: CHALLENGE_TYPE_VALUES;
    challengeList: {
      total: number;
      challenges: ChallengeDetail[];
    };
  };
  idResource: number;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ChallengeCertifiedCourse: FC<Props> = ({
  config,
  updateChallenges,
  challenges,
  user,
}) => {
  if (!config) return <React.Fragment />;

  const { formatNumber } = useIntl();

  const {
    creditsDescriptions,
    speaker,
    points,
    legalCheck,
    idChallenge,
    idCourse,
  } = config;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showCertifiedCourseModal, setShowCertifiedCourseModal] =
    useState(false);
  const [areLegalTermsAccepted, setAreLegalTermsAccepted] = useState(false);
  const [currentCourse, setcurrentCourse] = useState<{
    urlCertifiedCourse?: string;
    challenge?: IChallenge;
  }>({
    urlCertifiedCourse: '',
    challenge: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [legalCheckData, setLegalCheckData] = useState<{
    isAccepted: boolean;
    idResource: number;
  }>({
    idResource: 0,
    isAccepted: legalCheck.wasAccepted ?? false,
  });

  const modalCertifiedCourseContent = (
    <Col span={24} className="generic-button-modal__header">
      <p className="generic-button-modal__header--description m-t-20">
        <FormattedMessage
          id="challenge.resource.certified-course.certifiedCourse.redirect-description"
          values={{
            certifiedCoursePlatform: 'CertifiedCourse',
          }}
        />
      </p>

      <Row className="generic-buttom" justify="center">
        {areLegalTermsAccepted && (
          <Row className="justify-center">
            <a
              className={'btn-primary btn-primary--black certified-modal-link'}
              href={currentCourse.urlCertifiedCourse}
              target="_blank"
              onClick={() => closeModal()}
            >
              <FormattedMessage id="page.password-confirm.modal-button-access" />
            </a>
          </Row>
        )}
      </Row>
    </Col>
  );

  const modalContent = (
    <Col span={24} className="generic-button-modal__header">
      <h4 className="generic-button-modal__header--title">
        <FormattedMessage
          id="challenge.resource.certified-course.legal-check.title"
          values={{
            certifiedCoursePlatform: 'CertifiedCourse',
          }}
        />
      </h4>
      <p className="generic-button-modal__header--description">
        <FormattedMessage
          id="challenge.resource.certified-course.legal-check.description"
          values={{
            certifiedCoursePlatform: 'CertifiedCourse',
          }}
        />
      </p>
      <ChallengeLegalCheck
        {...{
          config: legalCheck,
          updateLegalData: setLegalCheckData,
        }}
      />

      <Row className="generic-buttom" justify="center">
        {
          <Button
            disabled={!legalCheckData.isAccepted}
            className={'btn-primary btn-primary--black'}
            onClick={() => fetchCourse()}
          >
            <FormattedMessage id="page.password-confirm.modal-button-accept" />
          </Button>
        }
      </Row>
    </Col>
  );

  const modal = (
    <CustomModal
      {...{
        loading,
        visible: isModalVisible,
        centered: true,
        content: showCertifiedCourseModal
          ? modalCertifiedCourseContent
          : modalContent,
        onCancel: () => setIsModalVisible(false),
        footer: null,
        showIcon: showCertifiedCourseModal,
        icon: `${configApp.APP.ASSET_PREFIX}/legal_condictions.svg`,
        customClass: 'generic-button-modal',
      }}
    />
  );

  const fetchCourse = async () => {
    setAreLegalTermsAccepted(false);
    setcurrentCourse({});
    setLoading(true);
    try {
      const response = await api.postDataCall({
        dataPath: apiPaths.CHALLENGES.START_CERTIFIED_COURSE,
        data: {
          // idCertifiedCourse: config.uuidResource,
          // idExternalValidator: externalValidator.uuidResource,
          userName: user.userMainData?.name,
          userLastname: user.userMainData?.lastname,
          idLegalCheck: legalCheck.idResource,
          idChallenge,
          idCourse,
        },
        callConfig: {},
      });
      updateChallenges({
        challengeType: 'certifiedCourses',
        challengeList: {
          total: challenges.total,
          challenges: challenges.challenges.map((challenge) => {
            if (challenge.challengeId != idChallenge) return challenge;

            return builderChallenge(response.data.challenge, user);
          }),
        },
      });
      setcurrentCourse(response.data);
      setAreLegalTermsAccepted(true);
      setShowCertifiedCourseModal(true);
    } catch (error) {
      console.error({ error });
    }
    setLoading(false);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const openModal = async () => {
    if (legalCheckData.isAccepted) {
      setShowCertifiedCourseModal(true);
      setIsModalVisible(true);
      await fetchCourse();
    }
    setIsModalVisible(true);
  };

  return (
    <>
      <Row className="ChallengeCertifiedCourse" onClick={() => openModal()}>
        <Row
          className="challenge-card--certified-course-details__speaker"
          align="middle"
        >
          <div className={`icon icon--user icon__reduce_75`} />
          {speaker}
        </Row>

        <Row
          className="challenge-card--certified-course-details__creditsDescriptions"
          align="middle"
        >
          <div className={`icon icon--certificate icon__reduce_75`} />
          {creditsDescriptions}
        </Row>

        <Row
          className="challenge-card--certified-course-details__bottom"
          justify="space-between"
          align="middle"
        >
          <Col xs={24} md={12}>
            <Row
              className="challengeCard__button"
              justify="start"
              align="middle"
              // onClick={() => openModal()}
            >
              <FormattedMessage id="page.challenge.certified_courses.see-detail" />
              <div className="icon icon--open" />
            </Row>
          </Col>

          {points >= configApp.APP.SHOW_POINTS_LIMIT && (
            <Col
              className="challenge-card--certified-course-details__points"
              xs={24}
              md={12}
            >
              <Row
                className={`details ${true && 'details--bold'}`}
                justify="center"
                align="middle"
              >
                <div className={`icon icon--puntos`} />

                <FormattedMessage
                  id={'page.challenge.win-{points}-points'}
                  values={{ points: formatNumber(points) }}
                />
              </Row>
            </Col>
          )}
        </Row>
      </Row>
      {modal}
    </>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    challenges: state.app.challenges.certifiedCourses,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateChallenges }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeCertifiedCourse);
