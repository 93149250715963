export interface ITransactionsResponse {
  items: ITransactionItemResponse[];
  search_criteria: Record<string, unknown>;
  total_count: number;
}

export interface ITransactionItemResponse {
  product_qr_id: string;
  addictional_data: Record<string, unknown>;
  entity_type: string;
  action_type: string;
  entity_id: string;
  transaction_id: number;
  customer_id: number;
  customer_name: string;
  customer_email: string;
  comment_to_customer: string;
  comment_to_customer_placeholder: string;
  comment_to_admin: string;
  balance: number;
  current_balance: number;
  transaction_date: string;
  website_id: number;
  balance_update_notified: number;
  type: number;
  entities: [];
  created_by: number;
}

export interface ITransactionDTO {
  balance: number;
  currentBalance: number;
  commentToCustomer: string;
  transactionDate: string;
}

export const getSanitizeTransaction = (
  transaction: ITransactionItemResponse
): ITransactionDTO => {
  const { balance, current_balance, comment_to_customer, transaction_date } =
    transaction;
  return {
    balance,
    currentBalance: current_balance,
    commentToCustomer: comment_to_customer,
    transactionDate: transaction_date,
  };
};
