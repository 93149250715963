import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomModal from 'src/components/CustomModal';
import { UploadFiles } from 'src/components/challenge/UploadFiles';
import { CustomCloseIcon } from 'src/components/shoppingCart/ShoppingCart';
import { PearlStatusEnum, S3BucketPathEnum } from 'src/shared/enums';
import { IUserUploadMediaData, MediaTypeEnum } from 'src/shared/models';
import { customFormatMessage } from 'utils';

const MAX_TITLE_LENGTH = 75;
const MAX_DESCRIPTION_LENGTH = 2000;
const MAX_IMAGES_ALLOWED = 3;

const ConditionsModal = ({
  conditions,
  showConditionsModal,
  setShowConditionsModal,
}) => {
  return (
    <CustomModal
      closeIcon={<CustomCloseIcon />}
      content={
        <Col className="custom-modal-container" span={24}>
          <Row
            justify="start"
            align="middle"
            className="conditions-modal__title"
          >
            {customFormatMessage('pearl.form.legal-terms-title')}
          </Row>
          <div
            className="conditions-modal__content block--small regular-body"
            dangerouslySetInnerHTML={{
              __html: conditions,
            }}
          />
        </Col>
      }
      centered
      customClass="challengeDetail--sell-in shopping-cart-modal conditions-modal"
      hideFooter={true}
      onCancel={() => setShowConditionsModal(false)}
      showIcon={false}
      visible={showConditionsModal}
    />
  );
};

const GoBackOrDeleteButton = ({
  editing,
  handleDeletePearl,
  setCreatePearl,
  setPearlSelected,
}) => {
  if (editing) {
    return (
      <p
        className="pearl__links pearl__form-legal-link"
        onClick={() => {
          handleDeletePearl();
        }}
      >
        {customFormatMessage('pearl.form.delete-pearl')}
      </p>
    );
  }

  return (
    <p
      className="pearl__links pearl__form-legal-link"
      onClick={() => {
        setCreatePearl(false);
        setPearlSelected(null);
      }}
    >
      {customFormatMessage('pearl.see-rest-of-pearls')}
      <span className="icon icon--arrow-dropdown-right"></span>
    </p>
  );
};

const PearlImagesRow = ({
  editing,
  pearlSelected,
  pearlImages,
  handleDeleteImage,
  isPearlValidated,
}) => {
  if ((editing || !pearlSelected) && !isPearlValidated)
    return (
      <Row className="pearl__uploaded-images" gutter={[30, 30]}>
        {pearlImages?.map((pearlImage, index) => (
          <Col key={`${pearlImage}-${index}`} span={24}>
            <Row justify="space-between" align="middle">
              <Col span={8}>
                <img className="pearl__editing-image" src={pearlImage} />
              </Col>
              <Col span={12}>
                <p>{pearlImage.split('/').pop()}</p>
              </Col>
              <Col span={4}>
                <div
                  onClick={() => handleDeleteImage(pearlImage)}
                  className="icon icon--delete"
                ></div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );

  return (
    <Row className="pearl__uploaded-images">
      {pearlImages?.map((pearlImage, index) => (
        <Col key={`${pearlImage}-${index}`} span={12}>
          <img className="pearl__see-image" src={pearlImage} />
        </Col>
      ))}
    </Row>
  );
};

const UploadFilesRow = ({
  disabledInputs,
  userUploadconfig,
  accessToken,
  setPearlImages,
  pearlImages,
}) => {
  if (disabledInputs) return <React.Fragment />;

  return (
    <div className="sell-out__report-now-block">
      <Row className="container-file-data" justify="center">
        <UploadFiles
          userUploadconfig={userUploadconfig}
          accessToken={accessToken}
          updateResource={(value) =>
            setPearlImages((prev: string[] | undefined) =>
              prev ? [...prev, value] : [value]
            )
          }
          btnDisabled={pearlImages?.length === MAX_IMAGES_ALLOWED}
          s3Bucket={S3BucketPathEnum.PEARLS_IMAGES}
        />
      </Row>
    </div>
  );
};

const TermsAndConditionsCheckbox = ({
  pearlAcceptTerms,
  disabledInputs,
  setPearlAcceptTerms,
  setShowConditionsModal,
}) => {
  return (
    <div className="loginForm__login-options--checkbox">
      <Checkbox
        checked={pearlAcceptTerms}
        onClick={() => setPearlAcceptTerms((prev: boolean) => !prev)}
        disabled={disabledInputs}
        className="checkbox-remember-login"
      >
        <span className="checkbox-text">
          {customFormatMessage('pearl.form.legal-terms', {
            legalTermsSpan: (
              <u onClick={() => setShowConditionsModal(true)}>
                {customFormatMessage('pearl.form.legal-terms-text')}
              </u>
            ),
          })}
        </span>
      </Checkbox>
    </div>
  );
};

const PearlForms = ({
  form,
  setCreatePearl,
  onAccept,
  pearl,
  accessToken,
  pearlSelected,
  setPearlSelected,
  isLoggedUserPearl,
  showOkModal,
  setShowOkModal,
  pearlConditions,
  handleDeletePearl,
}) => {
  const { formatMessage } = useIntl();
  const [editing, setEditing] = useState<boolean>(false);
  const [showConditionsModal, setShowConditionsModal] =
    useState<boolean>(false);
  const [pearlImages, setPearlImages] = useState<string[]>([]);
  const [pearlTitle, setPearlTitle] = useState<string>('');
  const [pearlDescription, setPearlDescription] = useState<string>('');
  const [pearlAcceptTerms, setPearlAcceptTerms] = useState<boolean>(false);
  const isPearlValidated =
    pearlSelected?.statusResource === PearlStatusEnum.VALIDATED;
  const isOnViewMode = !isLoggedUserPearl || (isLoggedUserPearl && !editing);
  const disabledInputs = pearlSelected && (isPearlValidated || isOnViewMode);
  const canSavePearl =
    pearlTitle?.length >= 1 &&
    pearlDescription?.length >= 1 &&
    pearlImages?.length >= 1 &&
    pearlAcceptTerms &&
    pearl.isPearlActive;

  const mediaData: IUserUploadMediaData = {
    fileName: null,
    idUserResource: null,
    isFirstTime: true,
    isUploaded: false,
    size: null,
    statusResource: PearlStatusEnum.NOT_SENT,
    type: MediaTypeEnum.IMAGE,
    url: null,
  };

  const userUploadconfig = {
    mediaData,
    ...pearl,
    allowedExtensions: ['image/png', 'image/jpg', 'image/jpeg'],
    buttonTitle: <FormattedMessage id="pearl.upload-file" />,
    maxImagesAllowed: MAX_IMAGES_ALLOWED,
  };

  const handleDeleteImage = (pearlImage: string) => {
    return setPearlImages((prev) =>
      prev?.filter((_pearlImage) => _pearlImage !== pearlImage)
    );
  };

  const CreateOrEditButton = () => {
    if (disabledInputs) {
      if (!isLoggedUserPearl || editing || isPearlValidated)
        return <React.Fragment />;

      return (
        <Button
          onClick={() => setEditing(true)}
          className="btn btn-ghost btn-ghost--black pearl__form-main-button"
        >
          {formatMessage({ id: 'pearl.edit-my-pearl' })}
        </Button>
      );
    }

    return (
      <Button
        disabled={!canSavePearl}
        htmlType="submit"
        onClick={() =>
          onAccept({
            title: pearlTitle,
            description: pearlDescription,
            image: pearlImages,
          })
        }
        className="btn btn-primary btn-primary--black pearl__form-main-button"
      >
        {formatMessage({ id: 'pearl.share-pearl' })}
      </Button>
    );
  };

  useEffect(() => {
    if (pearlSelected) {
      setPearlImages(pearlSelected.image);
      setPearlDescription(pearlSelected.description);
      setPearlTitle(pearlSelected.title);
      setPearlAcceptTerms(true);
    }
    return () => {
      setPearlAcceptTerms(false);
      setEditing(false);
    };
  }, [pearlSelected]);

  return (
    <Row justify="center">
      <Col xs={20} md={12} className="pearl__form">
        <Form form={form}>
          <Form.Item rules={[{ required: true }]}>
            <p className="pearl__form-labels">
              {formatMessage({ id: 'pearl.form.title' })}
            </p>
            {!disabledInputs ? (
              <>
                <Input.TextArea
                  value={pearlTitle}
                  onChange={(e) => setPearlTitle(e.target.value)}
                  maxLength={MAX_TITLE_LENGTH}
                  rows={4}
                />
                <p className="pearl__form-max-char">
                  {customFormatMessage('pearl.max-{limit}-characters', {
                    limit: MAX_TITLE_LENGTH,
                  })}
                </p>
              </>
            ) : (
              <p className="pearl__form-values pearl__form-values--title">
                {pearlTitle}
              </p>
            )}
          </Form.Item>
          <Form.Item rules={[{ required: true }]}>
            <p className="pearl__form-labels">
              {formatMessage({ id: 'pearl.form.description' })}
            </p>
            {!disabledInputs ? (
              <>
                <Input.TextArea
                  value={pearlDescription}
                  onChange={(e) => setPearlDescription(e.target.value)}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  rows={10}
                />
                <p className="pearl__form-max-char">
                  {customFormatMessage('pearl.max-{limit}-characters', {
                    limit: MAX_DESCRIPTION_LENGTH,
                  })}
                </p>
              </>
            ) : (
              <p className="pearl__form-values">{pearlDescription}</p>
            )}
          </Form.Item>
          <Row>
            {pearlSelected ? (
              <p className="pearl__form-labels">
                {formatMessage({ id: 'pearl.form.images-text-default' })} (
                {pearlImages?.length})
              </p>
            ) : (
              <p className="title pearl__form-big-text">
                {formatMessage({ id: 'pearl.form.images-text-create' })}
              </p>
            )}
          </Row>
          <PearlImagesRow
            {...{
              editing,
              handleDeleteImage,
              pearlImages,
              pearlSelected,
              isPearlValidated,
            }}
          />
          <UploadFilesRow
            {...{
              accessToken,
              disabledInputs,
              pearlImages,
              setPearlImages,
              userUploadconfig,
            }}
          />
          {!disabledInputs && (
            <TermsAndConditionsCheckbox
              {...{
                disabledInputs,
                pearlAcceptTerms,
                setPearlAcceptTerms,
                setShowConditionsModal,
              }}
            />
          )}
          <CreateOrEditButton />
        </Form>
        <GoBackOrDeleteButton
          {...{
            editing,
            handleDeletePearl,
            setCreatePearl,
            setPearlSelected,
          }}
        />
      </Col>
      <ConditionsModal
        {...{
          conditions: pearlConditions,
          setShowConditionsModal,
          showConditionsModal,
        }}
      />
      <CustomModal
        visible={showOkModal}
        content={
          <div className="sell-out__report-complete-modal">
            <p className="section-title">
              <FormattedMessage id="pearl.ok-modal.title" />
            </p>
            <p className="regular-body">
              <FormattedMessage id="pearl.ok-modal.text" />
            </p>
          </div>
        }
        centered
        showIcon={true}
        footer={false}
        onCancel={() => setShowOkModal(false)}
      />
    </Row>
  );
};

export default PearlForms;
